@import "@/condor/ui/common/Layout/layout.scss";

.selectedRow {
  td {
    background-color: $tableCellBackgroundColor;
  }
}

.updatingRow {
  td {
    background-color: $simpleWhite;
    opacity: 0.4;
  }
}

.editingCell {
  vertical-align: top;
  padding: 5px;

  .editableCellInput {
    min-width: auto;
  }
}
