@import "@/Framework/GlobalStyles/variables.scss";
@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.warningSection {
  background-color: $warningBackgroundColor;
  font-family: $fontFamily;
  padding: 15px 20px;
  color: $baseTextColor;
}

.areYouSureLabel {
  color: $cancelColor;
}
