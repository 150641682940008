@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/components/Input/input.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.formButtonsContainer {
  position: absolute;
  bottom: -105px;
  left: -20px;
  padding-bottom: 20px;

  button {
    display: inline-block;
  }
}

.successBtn {
  min-width: 100px;
}

.saveAndCreateButton {
  background-color: $successColor;
  &:hover {
    background-color: $successColorHover;
  }
}
