@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.addBannerHeader {
  background-color: #fff;
  padding: 10px 140px 10px 10px;
  color: $titleColor;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
}


.addBannerBtnContainer {
  width: 155px;
  text-align: left;
  position: relative;
  display: inline-block;
  background-color: #fff;
}

.addBannerBtn {
  padding-top: 3px;

  &.disabled {
    cursor: default;
    color: #fff;

    .addBannerIcon {
      fill: $secondary2Color;
    }

    &:hover {
      background-color: $secondary2Color;
      cursor: pointer;

      .addBannerIcon {
        fill: $secondary2Color;
      }
    }
  }

  &:hover {
    .addBannerIcon {
      fill: $actionColorHover;
    }
  }
}

.addBannerLabel {
  position: absolute;
  margin-left: 10px;
  top: 0;
}

.addBannerIcon {
  fill: $actionColor;
}
