@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.dropdownWrapper > span {
  display: block;
}

.profileDropdownContent {
  white-space: nowrap;

  .section {
    padding: 9px 0;
    border-bottom: 1px solid $defaultTableBorderColor;

    &:last-child {
      border: 0;
    }
  }

  a {
    text-align: left;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 9px 35px 9px 20px;
    font-family: $fontFamily;
    font-size: $baseFontSize;

    &:hover {
      background-color: $secondary4Color;

      .prefixIcon {
        fill: $actionColorHover;
      }
    }
  }

  .prefixIcon {
    fill: $actionColor;
  }

  .logoutLink {
    color: $cancelColor;

    .prefixIcon {
      fill: $cancelColor;
    }

    &:hover {
      color: $cancelColorHover;

      .prefixIcon {
        fill: $cancelColorHover;
      }
    }
  }
}
