@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/variables/_icons.scss";

.iconWrapper {
  padding: 41px 44px;
  background-color: $secondary5Color;
}

.fileName {
  margin-left: 20px;
  max-width: 150px;
  text-align: left;
  color: $titleColor;
}

.text {
  color: $secondary3Color;
  position: absolute;
  top: calc(50% - 18px);
  right: calc(50% - 95px);
}

.tip {
  color: $secondary2Color;
}

.icon {
  width: $iconL;
  height: $iconL;
  fill: $secondaryColor;
}
