@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.panelHeaderLabel {
  margin: 0 0 20px;
}

.inputField {
  width: 495px;
}

.groupNameInputContainer {
  max-width: 495px;
  margin: 0 0 30px;
}

.urlLabel {
  font-size: 11px;
  line-height: 23px;
  letter-spacing: 0.11px;
  color: $secondary3Color;
  word-break: break-word;
}

.footerTextarea {
  min-height: 60px;
  max-width: 495px;
  resize: vertical;
  max-height: 200px;
}

.formButtonsContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: absolute;
  bottom: -85px;
  left: 0;
  padding-bottom: 20px;
}

.deleteGroupButton {
  color: $cancelColor;

  &:hover {
    color: $cancelColorHover;
  }
}

