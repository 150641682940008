@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.deleteModal {
  :global(.modalDialog) {
    max-width: 450px;

    @include mqMaxWidth($screenXs) {
      max-width: calc(100% - 40px);
    }
  }
}

.modalTitle {
  text-transform: capitalize;

  :global(.modalHeaderTitle) {
    line-height: 23px;
  }
}

.content {
  div {
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 20px;
    }
  }
}
