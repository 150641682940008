@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.modalWrapper {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.modalBody {
  p {
    background-color: $disclaimerColor;
    padding: 10px;
  }
}
