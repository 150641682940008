@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.button {
  border: 0;
}

.filtersBtnCount {
  font-weight: normal;
}

.filtersIcon {
  @include mqMinWidth($screenXs) {
    margin-left: 10px;
  }
}
