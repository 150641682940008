@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.tableFooterLabel {
  text-decoration: underline;
}

.tableContainer {
  min-height: 77px;

  tbody tr td {
    padding: 8px 9px 9px;
    vertical-align: bottom;
  }

  // hidden by default and showing on hover
  .identifierCell {
    display: none;
    vertical-align: middle;
  }
}

.emptyMessage {
  background-color: transparent;
}

.row:hover {
  .identifierCell {
    display: table-cell;
  }

  .addedAtCell,
  .isNewCell,
  .dueByCell {
    display: none;
  }
}

.iconCopy,
.iconCopied {
  position: relative;
  top: -1px !important;
  margin-right: 6px !important;
  width: 12px;
}

.investorSetIconContainer {
  margin-right: 6px !important;
}

.investorSetIcon {
  position: relative;
  top: 1px !important;
  margin-right: 0 !important;
  width: 12px;
}

// actual checkbox
.togglerCheckbox {
  display: none;
}

// hide the label
.togglerCheckbox:checked ~ .copy {
  display: none;
}

// show success
.togglerCheckbox:checked ~ .copied {
  display: block;
}

.copied {
  display: none;

  color: $secondaryColor;

  i {
    fill: $secondaryColor;
  }
}

.tooltipContainer {
  text-align: left;

  span {
    color: $secondaryColor;
  }
}

.tooltipLabel {
  display: inline-block;
  min-width: 75px;
  margin-right: 5px;
}

.helperLabel {
  margin-left: 30px;

  @include mqMaxWidth($screen640) {
    margin: 0 0 10px;
    line-height: 18px;
  }
}

.tableFooter {
  @include mqMaxWidth($screen640) {
    > :first-child {
      display: flex;
      flex-direction: column-reverse;
      margin-bottom: -30px;
    }
  }
}
