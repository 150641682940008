@import "@dealroadshow/uikit/core/styles/headers.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.content {
  padding-top: 0;

  .restrictAccessWrapper {
    width: fit-content;
  }

  @include mqMaxWidth($screenXs) {
    padding-top: 10px;
  }
  :global(.Select-multi-value-wrapper) {
    max-height: 94px;
    overflow: auto;
  }
}

.heading {
  margin-top: 34px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.footer {
  text-align: left;
  padding-bottom: 0;
  padding-top: 43px;

  @include mqMaxWidth($screenXs) {
    padding: 10px 0 25px;
  }
}
