@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.deleteDealModal {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.deleteBtn {
  background-color: $cancelColor;

  &:hover {
    background-color: $cancelColorHover;
  }
}
