@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.detailsSectionsContainer {
  margin: 0 -20px;
}

.detailsSectionItemRow {
  position: relative;
  background-color: $secondary5Color;
  min-height: 60px;
  padding: 20px 0 0;
  margin: 0 0 10px;
}

.lastSectionItemRow {
  margin: 0;
}

.detailsSectionDragIconContainer {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  i {
    fill: $secondaryColor;
  }
}

.sectionContainer {
  padding: 0 0 0 46px;
}

.customSectionContainer {
  padding: 30px 0 0 46px;
}

.provideDetailsLabel {
  margin: 10px 0;
}

