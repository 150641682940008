.label {
  padding: 1px 0 0;
  max-width: 42px;
  width: 42px;
  margin-right: 10px;
  font-weight: 500;
  line-height: 16px;
}

.tooltipContent {
  text-align: left;
  padding: 3px 4px;
}

.tooltipItem {
  margin: 0;
}
