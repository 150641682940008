@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.ssoForm {
  display: flex;
  width: 100%;

  @media screen and (max-width: 960px) {
    flex-direction: column;
  }
}

.ssoFormTitle {
  color: $titleColor;
  font-family: $titleFontFamily;
  font-size: 20px;
  font-weight: 400;
  line-height: 25px;
  margin: 25px 0;
}
