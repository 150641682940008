@import "@dealroadshow/uikit/core/styles/headers.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.subscriptionsBlock {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      background: #fff;
      opacity: 0.7;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: none;
    }

    &.overlayVisible {
      &:after {
        display: block;
      }
    }
}

.tileRowWithTooltip {
    width: fit-content;
}

.tileInfo {
    margin-left: 20px;
    font-size: 12px;
    line-height: 17px;
    color: $secondaryColor;

    @include mqMaxWidth($screenS) {
        display: block;
        margin-left: 0;
        margin-top: 10px;
    }
}

.checkboxItem {
  margin: 0 20px 0 0;
}

.tileRow {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    &:last-child {
      margin-bottom: 0;
    }

    @include mqMaxWidth($screenS) {
      display: block;
    }
}

.cancelBtn {
    padding: 0 21px;
    line-height: 36px;
    font-weight: 600;
}

.flagIcon {
  width: 18px;
  height: 12px;
  margin-right: 10px;
  line-height: 12px;
}
