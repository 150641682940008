@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.modalIsFetching {
  :global {
    .modalBody {
      z-index: -1;
      position: relative;

      .spinner {
        position: sticky;
        width: 100%;
        height: 100%;
      }
    }
  }
}

.modal {
  :global {
    .modalDialog {
      width: fit-content;
      max-width: calc(100% - 40px);
    }

    .modalBody {
      padding: 0;
    }
  }
}

.discardModal {
  :global {
    .modalDialog {
      max-width: 330px;
    }
  }
}

.footer {
  padding-top: 20px;
}

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.editRow {
  background-color: $backgroundHighlightColor !important;

  &Title {
    color: $secondary3Color;
    font-size: 10px;
    font-style: italic;
    font-weight: 500;
    line-height: 7px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.progressBar {
  height: 10px;
  background-color: $actionColor;
}

.finishSuccess {
  background-color: $successColor;
}

.finishWithError {
  background-color: $warningColor;
}

.finishFullyErrors {
  background-color: $cancelColor;
}

.progressTotal {
  margin-top: 0;
}

.progressDetailsTable {
  margin-top: 8px;

  th,
  td {
    padding-top: 2px;
    padding-bottom: 2px;
  }

  td {
    padding-left: 8px;
  }
}

.progressErrorsTable {
  border-collapse: collapse;
  word-break: break-word;

  th,
  td {
    border: 1px solid $defaultTableBorderColor;
    padding: 4px;
  }
}
