@import "@/condor/ui/common/Layout/variables.scss";

.verifiedAccount {
  background: $verifiedColor;
  border-color: $verifiedColor;
}

.unverifiedAccount {
  background: $unverifiedColor;
  border-color: $unverifiedColor;
}

.deletedAccount {
  background: $unsubscribedColor;
  border-color: $unsubscribedColor;
}
