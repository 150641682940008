@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.uploadWrp {
  position: relative;
  min-height: calc(100vh - 180px);

  @include mqMaxWidth($screenM) {
    min-height: auto;
  }
}

.contentWrp {
  text-align: center;
  width: 100%;
  display: table;
  vertical-align: middle;
  height: calc(100vh - 180px);

  @include mqMaxWidth($screenM) {
    padding: 50px 15px;
    height: auto;
  }

  p {
    color: $baseTextColor;
    max-width: 530px;
    width: 100%;
    margin: 0 auto;
  }
}

.content {
  display: table-cell;
  vertical-align: middle;
}

.ctaTitle {
  font-family: $titleFontFamily;
  font-size: 36px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 27px;
  margin-bottom: 16px;
}

.ctaBtn {
  margin-top: 30px;
}

.downloadIcon {
  fill: $actionColor;
  height: 16px;
  width: 16px;
  margin-right: 10px;
  margin-bottom: -3px;
}

@include mqMaxHeight($screenS) {
  .contentWrp {
    padding: 20px 0;
  }
}
