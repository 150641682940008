@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.contentWrp {
  position: relative;
  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.buttonsWrp {
  @include mqMaxWidth(381px) {
    > button:first-child {
      margin-bottom: 10px;
    }
  }

  @include mqMaxWidth(323px) {
    > button:nth-child(2) {
      margin-bottom: 10px;
    }
  }
}

.modalWrp {
  :global(.modalDialog) {
    max-width: 600px;
  }
}

.messagesWrp {
  margin-bottom: -10px;
  margin-top: -10px;
  @include mqMaxWidth($screenS) {
    padding: 0 10px;
  }
}

.deactivateMessage {
  padding: 15px;
  background-color: #ffeded;
}

.activateMessage {
  padding: 15px;
  background-color: #fff7de;
}
