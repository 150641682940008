@import "../../common/common.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.modalForm {
  border-left: 1px solid $secondary2Color;
}

.section {
  position: relative;
  margin-bottom: 30px;
  padding-left: 19px;

  &:last-of-type {
    margin-bottom: 0;
    padding-bottom: 15px;
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    width: 17px;
    height: 17px;
    background-color: $secondary2Color;
    border: 5px solid #fff;
    z-index: 2;
    border-radius: 50%;
  }

  &.activeSection {

    &:before {
      background-color: $secondaryColorHover;
    }
  }
}

.warningBlock {
  display: flex;
  background-color: $backgroundHighlightColor;
  align-items: center;
  padding: 5px 10px 6px;
  position: absolute;
  right: 0;
  top: 0;

  p {
    margin: 0;
    font-size: 12px;
    line-height: 17px;
  }

  @include mqMaxWidth(768px) {
    position: static;
  }
}

.warningIcon {
  fill: #ffad0f;
  display: block;
  margin-right: 10px;
}

.warningBlockItem {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.label {
  text-transform: capitalize;
}

.title {
  margin: 0 0 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.emailsHint {
  color: $secondary3Color;
  font-size: 11px;
  margin: 0;
}

.indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 8px;
}

.starter {
  background-color: #845bb4;
}

.premium {
  background-color: $firmColor;
}

.pro {
  background: linear-gradient(213deg, $firmColor2 0%, $firmColor 100%);
}
