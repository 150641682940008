@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/links.scss";

.card {
  padding: 20px;
}

.header {
  display: flex;
  justify-content: space-between;

  margin-bottom: 30px;
}

.headerText {
  font-size: 20px;
  font-family: $titleFontFamily;
  line-height: 25px;
  color: $titleColor;
}

.wideInput {
  max-width: 427px;
}

.input {
  max-width: 206px;
}

.statusIndicator {
  width: 16px;
  height: 16px;
}

.createButtonWrp {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.deactivateButtonWrp {
  margin-left: auto;
}

@include mqMaxWidth($screenXs) {
  .hideTooltipOnMobile {
    display: none;
  }
}
