@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.searchInput {
  width: 100%;
  max-width: 338px;
  min-width: 240px;
  display: inline-block;
  font-size: 14px;

  @include mqMaxWidth($screenXs) {
    max-width: 100%;
  }
}

.accessType {
  position: relative;
  min-width: 114px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    .hide {
      opacity: 1;
      visibility: visible;
    }
  }
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 114px;
  padding: 5px 10px;
  line-height: 18px;
  height: inherit;
  font-weight: 400;
  margin-right: 0;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.searchInputWrapper {
  display: block;
  margin-left: auto;
}

.filterSection {
  display: inline-flex;
}

.truncateMessage {
  margin-right: 4px;
}
