@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrapper {
  display: flex;
  align-items: center;

  @include mqMaxWidth($screenS) {
    display: block;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.checkboxItem {
  margin-right: 20px;

  @include mqMaxWidth($screenS) {
    margin-right: 0;
    margin-bottom: 20px;
  }
}

.errorMessage {
  color: #cc4d4d;
  font-size: 11px;
  line-height: 20px;
  margin-bottom: -20px;

  @include mqMaxWidth($screenXs) {
    margin-bottom: -10px;
    margin-top: -10px;
  }
}
