@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.checkboxFilters {
  height: 36px;
  font-size: 14px;

  @include mqMaxWidth($screenM) {
    align-self: end;
    margin-right: 0;
    margin-bottom: 10px;
    width: 280px;
  }

  @include mqMaxWidth($screenXs) {
    align-self: start;
    margin-top: 10px;
    margin-bottom: 0;
    order: 2;
  }
}

.filtersWrapper {
  display: flex;
  justify-content: flex-end;

  @include mqMaxWidth($screenM) {
    flex-direction: column;
  }
}
.status {
  text-transform: capitalize;
}

.statusPending {
  color: $yellow3;
}

.statusApproved {
  color: $green;
}

.statusDenied {
  color: $red2;
}

.iconRight {
  fill: $gray;
  width: 10px;
}

.actionContainer {
  margin-right: 20px;
}

.iconApprove:not(:disabled) {
  fill: $green;
}

.iconDeny:not(:disabled) {
  fill: $red2;
}
