@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.linkToRoadshowDisabled {
  color: $secondaryColor;
  cursor: default;
  pointer-events: none;

  &:hover {
    background-color: transparent;
  }
}
