@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
.mappingContent {
  .buttonsWrp {
    position: static;
  }
  .migrateTitle {
    margin-bottom: 30px;
  }

  :global(.Select .Select-menu-wrapper .Select-menu-list-option) {
    > :local(.selectAccountOptions) {
      color: $successColor !important;
    }
  }
}
