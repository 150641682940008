.tableRowFieldsContainer {
  margin: 0 -20px;
}

.rowCell {
  vertical-align: top;
}

.labelContainer {
  display: flex;
  align-items: center;
  max-height: 18px;
}
