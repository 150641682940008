@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.sendEmailBlock {
  display: flex;
  align-items: center;
}

.emailAlertsHistory {
  margin: 0 20px 0 0;
  font-size: 14px;
  color: $ColorGrayscaleGray2;
}

.emailAlertItem {
  margin: 0;
  font-size: 12px;
  line-height: 17px;
  +.emailAlertItem {
    margin-top: 2px;
  }
}

.emailAlertLabel {
  color: $ColorGrayscaleGray3;
  padding-right: 3px;
}
