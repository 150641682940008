@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.statusCell {
  height: 8px;
  width: 16px;
  margin: 0 auto;
}

.dealNameCell {
  position: relative;
}

.dealNameCellWrapper {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notesIconWrp {
  left: -22px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;

  i {
    width: 12px;
    height: 12px;
    margin-top: -2px;

    path {
      fill: $secondaryColor;
    }
  }

  &:hover {
    i {
      path {
        fill: $baseTextColor;
      }
    }
  }
}

.multiSched {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &Icon {
    transform: translateY(-1px);
    margin-left: 5px;
  }
}

.multiIcon {
  margin-left: 5px;
  width: 12px;
  height: 12px;
  margin-bottom: 3px;

  path {
    fill: $secondaryColor;
  }

  &:hover {
    path {
      fill: $secondaryColorHover;
    }
  }
}

.roadshowId {
  > div:hover {
    display: flex;
    align-items: center;
    padding-right: 10px;
  }
}

.typeCell {
  padding-right: 30px !important;
  white-space: nowrap;
}
