@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.previewModal {
  :global(.modalDialog) {
    max-width: 1240px;
    width: 100%;
  }

  .modalContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    gap: 40px;
  }
}

.activeProfile {
  background: $ColorPrimaryGreen;
  border-color: $ColorPrimaryGreen;
}

.inactiveProfile {
  background: $ColorGrayscaleGray3;
  border-color: $ColorGrayscaleGray3;
}
