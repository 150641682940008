@import "@/Framework/GlobalStyles/variables.scss";
@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.nameCell {
  width: auto;
  min-width: 300px;
  color: $titleColor;

  &:hover {
    color: $titleColor;
  }

  &.isVerified {
    color: $verifiedColor;

    &:hover {
      color: $verifiedColor;
    }
  }
}

.checkboxEditableCell {
  vertical-align: middle;
  text-align: center;
}

.actionsCell {
  > div {
    position: absolute;
    right: 5px;
    top: 0;
    background-color: $tableRowHoverBackgroundColor;
    overflow: hidden;
    z-index: 2;
  }
}

.companiesTable {
  thead tr,
  tbody tr,
  tfoot tr {
    position: relative;

    th,
    td {
      &:last-child {
        padding-right: 10px;
      }
    }
  }
}
