@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/card.scss";
@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";

.filtersButton {
  line-height: 34px;
}

.isBlurred {
  filter: blur(4px);
}

.bondLayoutWrp {
  position: relative;
  width: 100%;
  height: 100%;
}

.bondsLayout {
  > div:last-child {
    margin: 0;
  }
}

.cardWrapper {
  position: relative;
}

.card {
  @extend .cardContainer;
}

.header {
  @extend .cardHeader;
  @extend .cardInner;
  min-height: 67px;
  padding-bottom: 0;

  @include mqMaxWidth($screenXs) {
    padding-right: 0;
  }
}

.summaryHeader {
  @extend .cardHeader;
  @extend .cardInner;
  padding-bottom: 0;

  @include mqMaxWidth($screenXs) {
    padding-right: 0;
  }
}

.headerLabel {
  font-size: 14px;
  margin-right: 30px;
  color: $actionColor;
  white-space: nowrap;

  @include mqMaxWidth($screenXs) {
    white-space: pre-wrap;
    flex-basis: 55%;
  }
}

.tabsInfo {
  @extend .headerLabel;
  display: inline-block;
  float: right;
  position: relative;
  margin-right: 0;
  margin-top: 10px;
  color: $secondaryColor;
  width: 80px;
}

.summaryTabsInfo {
  @extend .tabsInfo;
  margin-top: 0;
}

.subscribeButton {
  margin-right: 20px;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  float: right;
  position: relative;

  @include mqMaxWidth($screenS) {
    width: 100%;
  }
}

.buttonsWrp {
  display: inline-flex;
  align-items: center;

  @include mqMaxWidth($screenS) {
    margin-bottom: 15px;
    width: 100%;
  }
}

.searchInputWrapper {
  display: flex;
  align-items: center;
  justify-content: stretch;
  width: 350px;

  @include mqMaxWidth($screenS) {
    display: block;
    width: 100%;
  }

  @include mqMaxWidth($screenXs) {
    width: 90%;
  }
}

.searchInput {
  width: 100%;
}

.exportToExcelWrapper {
  margin-right: 20px;
  padding-top: 2px;

  span {
    vertical-align: middle;
  }
}

.exportToExcelSpinner {
  top: 10px;
}

.countInTab {
  cursor: pointer;
  position: relative;
  top: -1px;
  margin-left: 4px;
  padding: 1px 7px;
  width: 32px;
  height: 14px;
}

.explanationIcon {
  width: 12px;
  height: 12px;
}

.pageNavigation {
  position: relative;
  height: auto;
  @include clearfix;
}

.pageHeaderActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: 10px;

  @include mqMaxWidth(360px) {
    padding-bottom: 10px;
  }
}

.pageHeaderSummaryActions {
  padding: 8px 0 10px;
  margin-left: 10px;

  i {
    cursor: pointer;
    font-size: 16px;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    position: relative;
    fill: $actionColor;

    &:hover {
      fill: $actionColorHover;
    }

    @include mqMaxWidth($screen568) {
      margin-right: 15px;
    }
  }

  span {
    @include mqMaxWidth($screen568) {
      display: none;
    }
  }
}

.explanation {
  padding: 12px 0 18px;
}

.searchFormField {
  width: 100%;
}

.chartShowBtnWrapper {
  margin: 0 20px 0 10px;
}

.searchChartBtnWrapper {
  display: flex;

  @include mqMaxWidth($screenS) {
    width: 100%;
  }
}

.mobileHide {
  @include mqMaxWidth($screenXs) {
    height: 0;
    padding: 0 !important;
    margin: 0 !important;
    >* {
      display: none;
    }
  }
}
