@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/condor/ui/common/Layout/variables.scss";

.companySelectModal {
  :global(.modalDialog) {
    max-width: 600px;
  }
}

.migrateContent {
  .migrateInfo {
    padding: 20px;
    margin-bottom: 30px;
    background: $warningBackgroundColor;
  }
  .migrateLabel {
    display: block;
    margin-bottom: 20px;
    letter-spacing: 1px;
  }
  .migrateValue {
    font-size: 14px;
    color: $titleColor;
  }
}
