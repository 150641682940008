@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.progressModal {
  .mappingsProgressBar {
    height: 10px;
    background-color: $firmColor;
  }
  .mappingsFinishSuccess {
    height: 10px;
    background-color: $successColor;
  }
  .mappingsFinishError {
    height: 10px;
    background-color: $cancelColor;
  }
}
