@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/finsight/ui/components/variables.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$sidebarWidth: 700px !default;

.sidebar {
  height: 100%;
  max-width: 0;
  position: fixed;
  z-index: 98;
  top: 0;
  right: 0;
  background-color: #fff;
  transition: max-width 300ms ease-in-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  &.isSidebarOpen {
    max-width: $sidebarWidth;
    width: 100%;

    .spinnerOverlay {
      visibility: visible;
    }
  }

  .spinnerOverlay {
    position: fixed;
    max-width: $sidebarWidth;
    width: 100%;
    left: auto;
    right: 0;
    visibility: hidden;
  }
}

.responsiveContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarWrp {
  flex: 1;
  width: 100%;
  max-width: $sidebarWidth;
  min-width: $sidebarWidth;
  overflow-x: hidden;

  @include mqMaxWidth($sidebarWidth) {
    min-width: 100%;
  }
}

.sidebarOverlay {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 0;
  height: 0;
  z-index: 97;
  background-color: transparent;
  transition: background-color 200ms linear, max-width 0ms ease-out 300ms, height 0ms linear 300ms;

  &.isSidebarOpen {
    height: 100%;
    max-width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: background-color 200ms linear, max-width 0ms ease-out 0ms;
  }
}

.sidebarHeader {
  width: 100%;
}

.sidebarHeaderContent {
  position: relative;
  display: inline-block;
  padding: 15px 20px;
  max-width: $sidebarWidth;
  min-width: $sidebarWidth;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @include mqMaxWidth($sidebarWidth) {
    min-width: 100%;
  }
}

.headerTitle {
  float: left;
  margin-right: 25px;
}

.headerPeriod {
  float: left;

  @include mqMaxWidth(568px) {
    width: 100%;
    margin-top: 10px;
  }
}

.headerPeriodWrp {
  display: table;
}

.headerPeriodFrom {
  display: table-cell;
  width: 135px;
}

.headerPeriodLabel {
  display: table-cell;
  padding: 0 5px;
}

.headerPeriodTo {
  display: table-cell;
  position: relative;
  width: 135px;
}

.headerClose {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;

  i {
    fill: $actionColor;
    display: block;
  }

  &:hover {
    i {
      fill: $actionColorHover;
    }
  }
}

.footerContent {
  display: none;
}

.isSidebarOpen .footerContent {
  display: flex;
  max-width: $sidebarWidth;
  background-color: #fff;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  flex-wrap: wrap;

  @include mqMaxWidth($sidebarWidth) {
    min-width: 100vw;
  }
}

.isSidebarOpen .footerContent.emptyFooterTags {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @include mqMaxWidth($screenXs) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .footerTags {
    flex: 0 0 auto;
    margin-bottom: 0;
    align-items: center;

    @include mqMaxWidth($screenXs) {
      flex: 1 0 100%;
      margin-bottom: 15px;
    }
  }
}

.footerActions,
.footerTags {
  display: flex;
}

.footerTags {
  flex: 1 0 100%;
  margin-bottom: 15px;
  max-width: 100%;
  flex-wrap: wrap;
}

.zerocase {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  white-space: nowrap;
  color: #8c8c8c;
}

:global(.panelGroup) {
  .absoluteFilter {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 56px;
    @media all and (max-width: $screenXs) {
      right: 36px;
    }

    .absoluteFilterLabel {
      font-weight: 400;
      text-transform: none;
    }
  }
}
