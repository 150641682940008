@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.mapTableTrade {
  color: $titleColor;

  tbody {
    tr {
      background-color: $backgroundHighlightColor;
    }
  }
}

.tradeItemsTable {
  height: 542px;
  margin-bottom: 20px;
  font-weight: 400;
  color: $secondary3Color;

  > table {
    > thead {
      > tr {
        > th {
          display: none;
        }
      }
    }

  }
}

.modalBody {
  padding: 0;
}

.label {
  cursor: pointer;
  line-height: normal;
  padding: 1px 0;
  max-width: 42px;
  width: 42px;
}

.searchInputContainer {
  padding: 20px;
}
