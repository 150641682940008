@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$headerAndPaginatorHeight: 260px;

.tableWrapper {
  flex-grow: 1;
}

.form {
  min-height: calc(100vh - $headerAndPaginatorHeight) !important;
  display: flex;
}

.tableContainerStickyTable {
  height: auto;

  .spinner {
    z-index: 2;
    width: 100%;
    position: sticky;
    height: 100%;
  }

  > div:first-child {
    height: 100%;
  }

  tr:hover {
    background: $secondary5Color;
  }

  :global {
    .is-selected {
      background: $backgroundHighlightColor;

      &:hover {
        background: $backgroundHighlightColor;
      }
    }
  }
}

.tableContainerStickyLeft {
  .spinner {
    display: none;
  }

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.paginator {
  margin-top: 25px;
}

.table {
  tbody tr {
    td {
      padding: 2px 9px;
      height: 46px;
    }

    .actionsInActionsOnHoverCell {
      display: none;
    }

    @include mqMinWidth($screenM) {
      &:hover {
        .contentInActionsOnHoverCell {
          display: none;
        }

        .actionsInActionsOnHoverCell {
          display: block;
        }
      }
    }
  }
}

.stickyTableIsFetching > div {
  overflow: hidden;
}
