@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.panel {
  padding: 0;
  :global(.panelGroupContentActive) {
    padding: 0;
  }
  :global(.panelGroupHeader) {
     padding: 19px 67px 18px 20px;
  }
  :global(.panelGroupHeaderActive) {
    padding: 19px 67px 6px 20px;
  }

}

.panelHeader {
  display: flex;
  justify-content: space-between;
  line-height: 20px;
  .mappingsButton {
    height: 28px;
    line-height: 26px;
    font-weight: 400;

    &.disabledMappingsButton {
      border: 1px solid $secondaryColor;
    }
  }
}
