@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.checkboxFiltersContainer {
  display: inline-block;
  vertical-align: top;
  float: right;

  @include mqMaxWidth($screenL) {
    display: block;
    width: 100%;
    float: left;
    text-align: left;
  }

  @include mqMaxWidth($screenXs) {
    margin-top: 0;
  }
}

.filtersBlock {
  @include mqMaxWidth($screenL) {
    margin-top: 15px;
  }
}

.searchFilterInput {
  width: 350px;
  float: right;

  @include mqMaxWidth($screenXs) {
    width: 100%;
    float: left;
  }
}
