@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.pageHeaderTitle {
  word-break: break-all;
}

.pageDescription {
  color: $titleColor;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.14px;

  margin-bottom: 20px;
}
