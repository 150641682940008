@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.deleteTrancheBtn {
  display: inline-block;
  padding: 0;
  margin: 0;

  &:hover {
    cursor: pointer;

    .deleteTrancheIcon {
      fill: $actionColorHover;
    }
  }
}

.deleteTrancheIcon {
  fill: $actionColor;
}
