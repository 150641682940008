.displayTabs {
  margin-top: 50px;
}

.displayTabsDescription {
  line-height: 20px;
  letter-spacing: 0.01em;
  margin-top: 20px;
  margin-bottom: 10px;
}
