@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "../tranches.scss";

.arrowIcon {
  fill: $actionColor;
  height: 12px;
  width: 10px;
}

.tranchesHeader {
  height: 38px;
  border-top: 1px solid $secondary2Color;
  border-bottom: 1px solid $secondary2Color;
  background-color: #fff;
  position: sticky;
  top: -20px;
  z-index: 1;
  transition: top 0.6s, transform 0.6s;

  &.withStickyHeader {
    top: 36px;
  }
}

.leftArrowBtn {
  display: inline-block;
  padding: 2px 0 0 5px;
  border-radius: 0;
  height: 100%;
  border: 0;
  margin: 0;
  background-color: #fff;

  &.withShadow {
    @extend .leftInnerShadow;
  }

  &:hover {
    .arrowIcon {
      cursor: pointer;
      fill: $actionColorHover;
    }
  }
}

.rightArrowBtn {
  display: inline-block;
  float: right;
  padding: 2px 3px 0 0;
  border-radius: 0;
  height: 100%;
  border: 0;
  margin: 0;
  background-color: #fff;

  &:hover {
    .arrowIcon {
      cursor: pointer;
      fill: $actionColorHover;
    }
  }
}
