@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.loginLimitsContainer {
  display: inline-block;
  vertical-align: top;
}

.timeLimitsInput {
  display: inline-block;
  vertical-align: top;
  width: 128px;
  margin-left: 30px;
}

.customRoadshowContainer {
  position: relative;
}

.fieldsWrapper {
  margin-top: 15px;
}

.applyEvercall {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 200px;
}

.link {
  width: 12px;
  height: 36px;
  fill: $secondaryColor;
  margin-right: 10px;
  margin-top: 25px;
}

.evercallInput {
  max-width: 156px;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* stylelint-disable property-no-vendor-prefix */
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  /* stylelint-enable property-no-vendor-prefix */
}

.titleWrapper {
  margin-bottom: 6px;
}

.title {
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.error {
  font-size: 11px;
  color: $warningColor;
  position: absolute;
  bottom: 7px;
  left: 24px;
}

.evercallWrapper {
  position: relative;
  margin-left: 10px;
}

.checkboxGroupItem {
  margin-bottom: 15px;
}

.roadshowTypeField {
  margin-bottom: 15px;
}

.controlFormCheckboxTooltipIcon {
  margin-left: 4px;
  vertical-align: top;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;
    position: relative;
    top: 2px;

    svg {
      vertical-align: top;
    }

    &:hover {
      fill: $titleColor;
    }
  }
}

.disableNavigationTooltip {
  ul {
    padding-left: 20px;
    margin: 0;

    li {
      text-align: left;
      padding-left: 0;
    }
  }
}

.textArea {
  max-height: 108px;
}

.roadshowViewerCustomizeCheckbox {
  margin-bottom: 20px;
}

.customSectionHeaderText {
  max-width: 541px;
}

.typeAndModerated {
  margin-bottom: 15px;
}

.typeAndModeratedLine {
  display: flex;
  align-items: center;
}

.typeFormField {
  margin-bottom: 0;
  margin-right: 30px;
  flex: 1;
}

.internalNotesContainer {
  min-height: 150px;
}
