@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.wrapper {
  .manageButton {
    display: inline-block;
    margin: 0;
    height: 28px;
    line-height: 26px;
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.subscribersTable {
  tr td {
    height: 49px;
  }
}

.filtersWrapper {
  display: flex;
  justify-content: flex-end;

  @include mqMaxWidth($screenM) {
    flex-direction: column;
    gap: 10px;
  }
}

.plansFilters {
  display: flex;
  flex-direction: row;
  max-height: 36px;
  max-width: 329px;
  border: 1px solid $secondary2Color;
  border-radius: 3px;
  padding: 9px 19px;
  margin-right: 12px;
  gap: 20px;

  > * {
    margin: 0;
  }

  label {
    font-size: 14px;

    &:last-of-type {
      > *:last-child {
        padding-left: 8px;
      }
    }
  }

  @include mqMaxWidth($screenM) {
    align-self: end;
    margin-right: 0;
  }

  @include mqMaxWidth($screenL) {
    max-width: none;
    margin-top: 0;
  }
}
