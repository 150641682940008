@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.mappingsTableLabel {
  display: none;
}

.mappingsTable {
  :global(tbody tr) {
    background-color: $secondary5Color;
  }
  :global(thead tr th) {
    padding: 10px 9px;
  }
}

.emptyMessage {
  margin: 40px auto;
  text-align: center;
}
