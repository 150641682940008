@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.exportBtnWrapper {
  position: relative;
  overflow: hidden;
  outline: none;
}

.exportSpinner {
  display: inline-block;
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  margin: 1px 6px 0;
}

.exportBtn {
  position: relative;
  color: $actionColor;
  width: 16px;
  height: 20px;
  font-weight: 500;

  i {
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    position: absolute;
    top: 0;
    right: 0;
  }

  &:hover {
    color: $actionColorHover;

    i {
      fill: $actionColorHover;
    }
  }

  &.isDisabled {
    color: $secondaryColor;

    &:hover {
      color: $secondaryColor;

      i {
        fill: $secondaryColor;
      }
    }
  }
}
