@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.form {
  max-width: 100%;
  height: 100%;
}

.pageWrapper {
  height: auto;

  &.pageWrapperUpload {
    height: calc(100vh - 177px);
  }
}

.profileWrapperContainer {
  height: 100%;
  padding: 0;
}

.checkIcon {
  fill: $successColor;
  margin-top: -8px;
}
