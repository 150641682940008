@import "@/condor/ui/common/Layout/variables.scss";

.form {
  max-width: 778px;

  &.formModal {
    margin: -20px;
    max-width: inherit;
  }
}

.dealsAmountInput {
  flex: 1 0 auto;
  margin-right: 12px;
}

.dealsData {
  margin-top: 30px;
  max-width: $formFieldMaxWidth;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.formButtonsContainerModal {
  position: relative;
  top: auto;
  bottom: auto;
  padding-bottom: 0;
  margin: 20px 20px 0;
}

.formRow {
  width: 100%;
  display: flex;
}

.formColumn {
  width: 100%;
  max-width: 50%;
  margin-left: 20px;

  &:first-child {
    margin-left: 0;
  }
}

.checkboxContainer {
  display: flex;
  flex-direction: row;
  gap: 30px;
}
