@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.toolbar {
  background: #fff;
  display: flex;
  align-items: center;
  margin: 0 0 8px;

  &.isAtBottom {
    margin: 8px 0 0;
  }

  .popoverToolbarIcon {
    border: 0;
    outline: none;

    &:hover {
      background-color: $defaultBackgroundColor;
    }
  }

  .popoverToolbarIconActive {
    fill: $titleColor;
    background: $defaultBackgroundColor;
  }
}

.blockToolButton {
  padding: 6px 0 0 6px;
}

.pickerWrapper {
  width: 30px;
  height: 30px;
  border: 1px solid $btnDefaultColor;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  i {
    width: 12px;
    height: 12px;
  }
}

.picker {
  display: inherit;
  left: 20px;
  cursor: pointer;
}

.select {
  height: 30px;
  border: 1px solid $btnDefaultColor;
  width: 44px;
  padding: 2px 3px;
  margin-right: 6px;
  border-radius: 1px;
  position: relative;
  cursor: pointer;

  &:focus-visible {
    border-color: $btnDefaultColor;
  }
}

.popoverToolbarContainer {
  border-radius: 3px;
}
