@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.pageHeaderRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.pageHeaderRowCheckboxes {
  display: flex;
  align-items: center;
}

.pageHeaderCheckbox {
  + .pageHeaderCheckbox {
    margin-left: 20px;
  }
}

.tableWrapper {
  @include mqMaxWidth($screenL) {
    padding-top: 40px;
  }
}
