@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.inputWrapper {
  display: flex;
  flex-flow: row wrap;
}

.smallInput {
  margin: 0 10px 0 0;

  @include mqMaxWidth($screenXs) {
    width: 100%;
  }
}

.buttonWrp {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 2em;
}

.buttonRight {
  margin-left: auto;
}

.deleteBtn {
  margin-left: auto;
  color: $cancelColor;

  &:hover {
    color: $cancelColorHover;
  }
}

.saveInfo {
  color: $secondaryColor;
  font-size: 11px;
  position: relative;
  display: inline-block;
  height: 36px;
  line-height: 16px;
  vertical-align: middle;
  cursor: pointer;
  width: 200px;
}

.positionsBlock {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.checkbox {
  flex: 0 0 31.3333%;
  margin: 10px;
  font-family: $fontFamily;

  @include mqMaxWidth($screenXs) {
    &:first-child {
      float: left;
    }
    &:last-child {
      float: right;
    }
  }
}

.label {
  flex: 0 0 31.3%;
  font-style: normal;
  font-weight: 500;
  align-items: center;
  letter-spacing: 0.1em;
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  color: $secondary3Color;
  line-height: 10px;
  margin-bottom: 8px;
}
