@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/variables/_icons.scss";

.mapButton,
.mapButton:disabled {
  padding: 4px 10px;
  font-size: 14px;
  height: 28px;
  line-height: 20px;
  font-weight: 400;
}

.conflictIcon {
  fill: $red2;
  width: $iconS;
  height: $iconS;
  margin-left: 4px;
  vertical-align: baseline !important;
}
