@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.separator {
  height: 20px;
  width: 0;
  border-left: 2px solid $secondary2Color;
}

.button {
  height: 20px;
  line-height: normal;

  .icon {
    svg {
      fill: $actionColor;
    }
  }

  &:disabled {
    .icon {
      display: block;

      svg {
        fill: $secondary2Color;
      }
    }
  }
}

.tranchesLength {
  margin-left: 5px;
  color: $secondary2Color;
}
