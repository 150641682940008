@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$opacityHidden: 0;
$opacityVisible: 1;

.stickyHeader {
  height: 0;
  width: 100%;
  position: sticky;
  top: -20px;
  background-color: #fff;
  z-index: 3;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  font-size: 20px;
  line-height: 36px;
  color: $titleColor;
  opacity: $opacityHidden;
  transition: opacity 0.6s, transform 0.6s;
  transform: translateY(-56px);
  pointer-events: none;

  &.visible {
    opacity: $opacityVisible;
    transform: translateY(0);
    pointer-events: all;
    height: 56px;
  }
}

.content {
  padding: 10px 20px;
}

.buttons {
  float: right;

  button {
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }
}
