@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.contactEmail {
  color: $secondaryColor;
}

.agingHeaderWrp {
  position: relative;
  display: inline-block;
  min-width: 46px;
}

.agingTitle {
  padding-right: 18px;
}

.questionWrapper {
  position: absolute;
  top: 2px;
  right: 0;
  min-width: 12px;
  margin: 0 2px;

  &:hover {
    .questionIcon {
      fill: $secondaryColorHover;
    }
  }
}

.questionIcon {
  width: 12px;
  height: 12px;
  fill: $secondaryColor;
}
