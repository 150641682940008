
.modal {
  :global(.modalDialog) {
    max-width: 885px;
    transform: none;
  }
}

.description {
  margin: 0;
  padding-bottom: 20px;
}

