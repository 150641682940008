@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.formInputWrp {
  width: 100%;
  max-width: 495px;
}

.url {
  font-size: 11px;
  line-height: 23px;
  letter-spacing: 0.11px;
  color: $secondary3Color;

  &Field {
    position: relative;
    max-width: 495px;
    margin-bottom: 30px;
  }

  &Copy {
    position: absolute;
    right: -22px;
    top: 33px;

    &Icon {
      width: 12px;
      fill: $actionColor;
      cursor: pointer;
    }
  }
}
