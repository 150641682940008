@import "@dealroadshow/uikit/core/styles/variables/colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/truncateTextLines.scss";

.tableRow {
  position: relative;
}

.name a {
  font-weight: 500;
  max-width: 330px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.roadshowLinkIcon {
  width: 12px;
  height: 12px;
  fill: $actionColor;
}

.profilesCount {
  border-radius: 2px;
  padding: 1px 3px 0;
  font-weight: 700;
  font-size: 10px;
  line-height: 12px;
  color: #fff;
  background-color: $secondaryColor;
  margin-right: 5px;
  position: relative;
  top: -1px;
}

.profilesTooltipAction {
  @include truncateTextLines();
  word-break: break-all;

  position: relative;
  top: 2px;

  &:hover {
    .profilesCount {
      background-color: $secondary3Color;
    }
  }
}

.dataTableContainer {
  flex-grow: 1;

  th {
    &:nth-last-child(2) {
      padding-right: 20px;
    }

    &.actionButtonsCell {
      position: absolute;
      pointer-events: none;
      left: 0;
    }
  }

  td.actionButtonsCell {
    position: absolute;
    right: 0;
    opacity: 0;
  }

  tbody {
    tr {
      td {
        padding: 13px 9px;

        &:nth-last-child(2) {
          padding-right: 20px;
        }
      }

      &:hover {
        td.actionButtonsCell {
          opacity: 1;
        }

        .hideOnHover {
          opacity: 0;
        }
      }
    }
  }
}

.actionButton {
  background: transparent;
  width: 16px;
  height: 16px;
  display: grid;
  margin: 0;

  &:hover {
    background: transparent;

    i {
      fill: $actionColorHover;
    }
  }

  &[disabled] {
    i {
      fill: $secondaryColor;
    }
  }

  &Tooltip {
    margin-left: 20px;
    display: inline-block;

    &Container {
      display: inline;
    }

    i {
      margin: 0;
      top: 0;
      fill: $actionColor;
    }
  }
}

.statusIndicator {
  float: none;
  display: block;
  margin: 0 auto;
}

.profilesTooltip {
  padding: 2px 0;

  th,
  td {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-align: left;
    padding: 2px 4px;
    vertical-align: top;
    word-break: break-word;
  }

  th {
    text-transform: uppercase;
    color: $secondary3Color;
    font-weight: 400;

    &:first-child {
      white-space: nowrap;
    }
  }

  td {
    color: #fff;
  }

  .profileName {
    min-width: 100px;
  }
}
