@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/truncateTextLines.scss";

.disabledBtn {
  pointer-events: none;

  svg {
    fill: $secondary2Color;
  }
}

.cardHeader {
  padding: 20px 20px 10px;

  @include mqMaxWidth($screen568) {
    display: flex;
    flex-direction: column;
  }
}

.cardHeaderTitle {
  vertical-align: top;

  h3 {
    float: left;
  }

  @include mqMaxWidth($screen568) {
    margin-bottom: 20px;
  }
}

/* HEADER */

.headerTitleActions {
  margin-left: 20px;
  border-left: 1px solid #dadada;
  padding-bottom: 2px;
  display: inline-block;

  .headerTitleActionsButton {
    margin-left: 20px;
    margin-top: 5px;

    button {
      width: 16px;
      height: 16px;
    }
  }
}

.headerActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @include mqMaxWidth($screen568) {
    justify-content: flex-start;
    flex-direction: column;
    align-items: self-start;
  }
}

.xlsDownload {
  @include mqMaxWidth($screen568) {
    position: absolute;
    right: 20px;
    top: 20px;

    i {
      margin-right: 0;
    }
  }
}

.searchInputWrapper {
  margin-left: 22px;
  flex-grow: 1;
  max-width: 380px;

  @include mqMaxWidth($screen568) {
    max-width: 100%;
    width: 100%;
    margin-left: 0;
  }
}

/* MODAL */

.underwritersModal {
  :global {
    .modalDialog {
      max-width: 600px;
    }

    .modalBody {
      padding: 20px 0;
    }
  }
}

/* CALLBACKS */

.oneLine {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}

.oneInlineLine {
  @include truncateTextLines();
}

.underwriters {
  white-space: nowrap;
}

.statusCallbackWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.statusCallback {
  width: 8px;
  height: 8px;
  border-radius: 50%;

  &Green {
    background: $successColor;
  }

  &Orange {
    background: #ffad0f;
  }

  &Gray {
    background: $secondary2Color;
  }
}

.taggedCallback {
  fill: $secondaryColor;
  width: 16px;
  margin: 0 auto;
  display: block;
}

.dateCallback {
  position: relative;
}

.editButtonWrapper {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.editButton {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  button {
    width: 16px;
    height: 16px;
  }
}

.tooltip {
  top: 2px;
  position: relative;
}

.mobileActions {
  display: table-cell;

  @include mqMinWidth($screenM) {
    display: none;
  }
}

/* FORM */

.dealNameField {
  > div {
    margin-bottom: 0;
  }
}
