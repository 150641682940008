@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$defaultBackgroundColor: linear-gradient(249.96deg, #563082 1.76%, #382152 100%);
$sidebarWidth: 200px;
$sidebarCollapsedWidth: 56px;
$transitionDuration: 200ms;
$headerHeight: 44px;

:export {
  defaultBackgroundColor: $defaultBackgroundColor;
}

.container {
  background-image: $defaultBackgroundColor;
  background-size: 400% 400%;
  max-width: $sidebarWidth;
  width: 100%;
  height: 100%;
  float: left;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  transition: max-width $transitionDuration;

  @include mqMaxWidth($screenS) {
    height: $headerHeight;
    bottom: inherit;
    max-width: 100%;
    transition: height $transitionDuration;

    &.isMobileMenuOpen {
      height: 100%;
    }
  }

  &.isCollapsed {
    max-width: $sidebarCollapsedWidth;

    @include mqMaxWidth($screenS) {
      max-width: 100%;
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $defaultBackgroundColor;
  position: relative;
  padding: 20px 0;
  height: 61px;
  z-index: 3;
  transition: background 300ms ease, max-width $transitionDuration;

  @include mqMaxWidth($screenS) {
    width: 100%;
    height: 44px;
    padding: 0;
  }
}

.gradientChanged {
  background-position: 0 100% !important;
  transition: background 700ms ease, max-width $transitionDuration;
}

.headerMobile {
  background-color: transparent;

  @include mqMaxWidth($screenS) {
    background: none;
    width: 100%;
    height: 44px;
    padding: 0;
  }
}

.sidebarItemsContainer {
  position: relative;
  height: calc(100% - 61px);

  @include mqMaxWidth($screenS) {
    display: none;
    height: 0;
    transition: height $transitionDuration;

    &.isMobileMenuOpen {
      display: block;
      height: calc(100% - 44px);
      overflow: auto;
    }
  }
}

.navigationItemsContainer {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 72px);
  z-index: 1;

  @include mqMaxWidth($screenS) {
    position: relative;
  }
}

.userProfileWrapper {
  position: absolute;
  width: 100%;
  bottom: 0;
  z-index: 2;
  padding: 20px;
  transition: background 300ms ease, padding $transitionDuration;

  &.withoutText {
    padding: 20px 13px;

    .menuPopoverContent {
      transform: translate(0, -10px);
    }
  }

  @include mqMaxWidth($screenS) {
    padding: 14px;
  }
}

.pageContent {
  --page-content-bottom-offset: 20px;

  box-sizing: border-box;
  position: relative;
  padding: 20px 20px var(--page-content-bottom-offset) $sidebarWidth + 20px;
  transition: padding $transitionDuration;
  min-height: 100%;

  @include mqMaxWidth($screenS) {
    --page-content-bottom-offset: 10px;

    padding: 20px + $headerHeight var(--page-content-bottom-offset) 10px;
  }

  &.isCollapsed {
    padding: 20px 20px var(--page-content-bottom-offset) $sidebarCollapsedWidth + 20px;

    @include mqMaxWidth($screenS) {
      padding: 20px + $headerHeight 10px var(--page-content-bottom-offset);
    }
  }
}

.isPanelContentHidden {
  @include mqMinWidth($screenS) {
    display: none;
  }
}

@mixin activeItemState {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;

  &.isSub {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .navItemIcon {
    fill: #fff;
  }
}

.navItemWrapper {
  position: relative;

  &:hover {
    .navItem {
      @include activeItemState;
    }

    .refreshBtn {
      display: block;
    }

    .actionBtn {
      display: block;

      @include mqMaxWidth($screenS) {
        display: none;
      }
    }
  }
}

.navItem {
  position: relative;
  display: block;
  text-decoration: none;
  color: rgba(255, 255, 255, 0.6);
  font-size: 14px;
  min-height: 38px;
  line-height: 16px;
  box-sizing: border-box;

  &.isSub {
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.5);

    .navItemText {
      position: relative;
      white-space: normal;
    }
  }

  &.isActive {
    @include activeItemState;

    .navItemText {
      font-weight: bold;
    }

    &.isSub {
      .navItemText {
        font-weight: normal;
      }
    }
  }

  &.noAppInformation {
    cursor: default;

    .navItemText {
      color: rgba(255, 255, 255, 0.25);
    }

    .navItemIcon {
      fill: rgba(255, 255, 255, 0.25);
    }
  }

  .tooltipWrp {
    display: block;
    box-sizing: border-box;
    height: 38px;

    @include mqMaxWidth($screenS) {
      height: 44px;
    }
  }

  .tooltipContainer {
    padding: 11px 20px 11px 46px;
    width: 100%;
    height: 100%;

    @include mqMinWidth($screenS) {
      &.isTooltipActive {
        padding: 11px 20px 11px 36px;
      }
    }

    @include mqMaxWidth($screenS) {
      padding: 13px 20px 13px 47px;
    }
  }
}

.navItemText {
  font-family: $fontFamily;
  white-space: nowrap;

  &.textHidden {
    @include mqMinWidth($screenS) {
      display: none;
    }
  }
}

.navItemIcon {
  position: absolute;
  left: 20px;
  top: 11px;
  fill: rgba(255, 255, 255, 0.6);

  @include mqMaxWidth($screenS) {
    width: 12px;
    height: 12px;
    left: 25px;
    top: 15px;
  }
}

.tooltipContentContainer {
  @include mqMaxWidth($screenXs) {
    display: none;
  }
}

.logo,
.mobileLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
}

.logo {
  transition: visibility 200ms, opacity 100ms linear 50ms;

  svg {
    height: 20px;
    display: block;
  }

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.mobileLogo {
  margin-left: 15px;
  display: none;

  @include mqMaxWidth($screenS) {
    display: block;
    height: 14px;
  }
}

.collapsePosition {
  margin-right: 10px;

  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.headerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: 0;
  fill: #fff;
  outline: 0;
  fill: rgba(255, 255, 255, 0.6);
  border-radius: 3px;
  z-index: 2;
  height: 26px;
  width: 26px;

  i {
    width: 10px;
    height: 14px;
    margin-top: 4px;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);

    svg {
      fill: #fff;
    }
  }
}

.collapseButton {
  @include mqMaxWidth($screenS) {
    display: none;
  }
}

.toggleMobileMenuButton {
  display: none;

  @include mqMaxWidth($screenS) {
    display: block;
    margin-right: 15px;
    padding: 0;
  }

  svg {
    fill: #fff;
  }
}

.collapseIcon {
  position: relative;

  svg {
    width: 10px;
    height: 10px;
  }
}

.isCollapsed {
  .logo {
    display: none;
  }

  .avatarName {
    @include mqMinWidth($screenS) {
      opacity: 0;
      visibility: hidden;
    }
  }

  .collapsePosition {
    margin-right: 0;
  }

  .header {
    justify-content: center;
  }

  .navItemWrapper:hover {
    .actionBtn {
      display: none;
    }
  }
}

.avatarName {
  position: absolute;
  left: 30px;
  transition: visibility 50ms, opacity 50ms linear 50ms;
}

.refreshBtn,
.actionBtn {
  padding: 0;
  outline: none;
  background: 0;
  border: 0;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 15px;
  display: none;

  svg {
    fill: rgba(255, 255, 255, 0.6);
  }

  &:hover {
    svg {
      fill: #fff;
    }
  }
}

.menuPopoverContent {
  margin-left: 10px;
  transform: translate(10px, -10px);
}

.menuArrowPopover {
  margin-bottom: 8px;
  z-index: 1;
}
