@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.link {
  display: inline;
  position: relative;
}

.linkPopup {
  position: absolute;
  left: 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  background-color: #fff;
  padding: 6px 10px;
  border-radius: 6px;
  border: 1px solid $secondaryColor;
  min-width: 100px;

  .linkText {
    min-width: max-content;
  }
}

.removeLinkButton {
  margin: 0;
  padding: 0;
  width: 16px;
  height: 16px;

  i {
    vertical-align: top;
    fill: $actionColor;
  }

  &:hover {
    i {
      fill: $actionColorHover;
    }
  }
}
