@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$border: 1px solid $secondary2Color;
$disabledButtonColor: $btnDefaultColor;
$disabledTextColor: $secondaryColor;

.filtersWrapper {
  display: inline-block;

  // tooltip's span wrapper
  > span {
    &:first-of-type {
      .filter {
        border-radius: 3px 0 0 3px;
      }
    }

    &:last-of-type {
      .filter {
        border-right: $border;
        border-radius: 0 3px 3px 0;
      }
    }
  }

  > button.filter {
    &:first-of-type {
      border-radius: 3px 0 0 3px;
    }

    &:last-of-type {
      border-right: $border;
      border-radius: 0 3px 3px 0;
    }
  }
}

.filter {
  min-width: 52px;
  height: 26px;
  padding: 0 8px;
  text-align: center;
  background: #fff;
  border: $border;
  border-right: 0;
  outline: 0;

  &:not(.isActive):hover {
    background: #f2f2f2;
  }

  &.isActive {
    background: $defaultBackgroundColor;
    font-weight: 500;
  }

  &.isDisabled {
    color: $disabledTextColor;
    background: $disabledButtonColor;
    cursor: default;
  }
}
