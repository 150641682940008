@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.switcherWrp {
  margin-top: 22px;
  margin-bottom: 22px;
}

.switcherRadio {
  @include mqMaxWidth(640px) {
    margin-bottom: 10px;
  }
}
