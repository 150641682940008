@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

@mixin titleLabel {
  color: $secondary3Color;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 7px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.modalContainer {
  :global(.modalDialog) {
    max-width: 450px;
  }
  :global(.modalContent) {
    position: relative;
  }

  &.modalContainerWide {
    :global(.modalDialog) {
      max-width: 800px;
    }
  }
}

.userPanelContainer {
  background-color: #fff7de;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;

  .userPanelItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    .userPanelFieldName {
      @include titleLabel;
      width: 85px;
    }

    .userPanelFieldValue {
      color: $baseTextColor;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.14px;
    }
  }
}

.featuresTitle {
  @include titleLabel;
  margin-top: 20px;
  margin-bottom: 10px;
}
.plansPanelContainer {
  background-color: #fff;
  margin-top: 20px;

  .plansPanelTitle {
    @include titleLabel;
    margin: 0;
  }

  .plansGroupContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 10px;
  }

  .planLabelContainer {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;

    .textLabel {
      color: $titleColor;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      letter-spacing: 0.14px;
    }
  }
}

.teamContainer {
  background-color: #fff;
  margin-top: 30px;

  .teamContainerTitle {
    @include titleLabel;
    margin-bottom: 10px;
  }
}
