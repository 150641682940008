@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.navSectionTitle {
  margin: 9px 0 10px 20px;
  color: rgba(255, 255, 255, 0.6);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &.isSidebarCollapsed {
    margin-left: 14px;
  }

  @include mqMaxWidth($screenS) {
    margin: 16px 0 16px 25px;
  }
}

.counter {
  margin: 0 0 1px 5px;
  background-color: rgba(255, 255, 255, 0.3);
  color: #fff;
}
