@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.exportBtnWrapper {
  position: relative;
  padding: 0;
  overflow: hidden;
  outline: none;

  @include mqMaxWidth($screenS) {
    left: 0;
    right: 0;
    top: 0;
    margin-top: 0;
  }
}

.exportBtn {
  position: relative;
  color: $actionColor;
  line-height: 36px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;

  @include mqMaxWidth(890px) {
    span {
      display: none;
    }
  }

  &:hover {
    color: $actionColorHover;

    i {
      fill: $actionColorHover;
    }
  }

  &.isDisabled {
    color: $secondaryColor;

    &:hover {
      color: $secondaryColor;

      i {
        fill: $secondaryColor;
      }
    }
  }
}
