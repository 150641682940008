@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$animationSpeed: 200;
@value animationSpeed: #{$animationSpeed};

.ieWrapper {
  display: flex;
  flex-direction: column;
}

.container {
  width: 100%;
  min-height: 40px;
  height: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  transition: height $animationSpeed + ms, min-height $animationSpeed + ms, opacity $animationSpeed + ms;
  padding: 5px 10px;
  word-break: break-all;
  margin-bottom: 30px;

  p {
    margin: 0;
  }
}

.label {
  font-weight: 500;
  letter-spacing: 0.1em;
  display: inline-block;
  font-size: 10px;
  text-transform: uppercase;
  color: #8c8c8c;
  line-height: 10px;
  margin-bottom: 8px;
}

.content {
  flex: 1;
  padding: 5px 10px;
}

.closeIcon {
  width: 12px;
  height: 12px;
  margin-right: 10px;

  svg {
    width: 12px;
    height: 12px;
    fill: $actionColor;

    &:hover {
      fill: $actionColorHover;
    }
  }
}
