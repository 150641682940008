@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.userPlanTag {
  display: inline-block;
  padding: 1px 3px 0;
  border-radius: 2px;
  font-size: 10px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: normal;
  color: #fff;
}

.userPlanStarter {
  background-color: #845bb4;
}

.userPlanPremium {
  background-color: $firmColor;
}

.userPlanPro {
  background: linear-gradient(213deg, $firmColor2 0%, $firmColor 100%);
}
