.searchWrp {
  display: inline-block;
  width: 300px;
}

.filterRadioGroups {
  display: inline-block;
}

.filterRadioGroup {
  padding: 7px 9px;
}

.filterRadioGroupLabel {
  display: inline-block;
  margin-right: 10px;
}
