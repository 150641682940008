@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.saveBtn {
  width: 100px;
}

.deleteBtn {
  float: right;
  margin: 0;
  color: $cancelColor;

  &:hover {
    color: $cancelColorHover;
  }
}
