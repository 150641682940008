@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.button,
.button:disabled {
  padding: 0;
}

.icon {
  fill: $actionColor;
}

.button:disabled {
  .icon {
    fill: $secondary2Color;
  }
}
