.statusContainer {
  position: absolute;
  z-index: 1;
  top: 20px;
  right: 20px;
}

.statusCircleBig {
  height: 8px;
  width: 8px;
  margin-bottom: 5px;
  border-radius: 10px;
}
