.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  opacity: 0;
}

.addButton {
  margin-top: 20px;
  font-weight: 700;
}

.removeButton {
  width: 24px;
  height: 24px;

  i {
    margin-right: 0;
    top: 0;
  }
}

.emptyMessage {
  padding: 12px;
  text-align: center;
}

.dataTableWrapper {
  margin-left: -20px;
  margin-right: -20px;
}

.dataTable {
  tr {
    &:hover {
      .actions {
        opacity: 1;
      }
    }
  }

  tbody {
    tr {
      td {
        vertical-align: baseline;
        padding-top: 4px;
        padding-bottom: 4px;

        &.draggableCol {
          vertical-align: baseline;
        }

        &.top {
          vertical-align: top;
        }
      }
    }
  }
}

.whitelistButton {
  font-weight: 400;

  &.isDisabled {
    background-color: transparent;
    color: #c0c0c0;
  }
}

.cellTitleWithTooltip {
  display: inline-flex;
  gap: 4px;

  svg {
    fill: #c0c0c0;
  }

  .tooltipContainer {
    display: inline;
  }
}
