@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tableContainer {
  margin: 0 -20px;
}

.checkboxCell {
  text-align: center;
  width: 160px;
  position: relative;
}

.actionBtn {
  svg {
    fill: $actionColor;
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }
}

.deleteRowCell {
  position: relative;
}

.deleteRowBtn {
  @extend .actionBtn;
  padding: 0;
  margin: 0;
  top: 0;
  right: 23px;
  position: absolute;
}

.addButton {
  @extend .actionBtn;
  margin: 15px 0 0 -20px;
}

.addIcon {
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}
