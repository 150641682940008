@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.searchInput {
  max-width: 385px;
  min-width: 300px;
  width: 100%;

  @include mqMaxWidth($screenXs) {
    min-width: auto;
  }
}
