.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 4px;
}

.error {
  margin-top: 8px;
}

.infoIcon {
  width: 12px;
  height: 12px;
  fill: #dadada;

  &:hover {
    fill: #000;
  }
}

.optionLabel {
  display: inline-flex;
  align-items: center;
}

.tooltip {
  margin-left: 5px;
  line-height: 12px;
}
