@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.initialPreloaderWrp {
  padding: 20px 0;
}

.searchInput {
  width: 100%;
  max-width: 337px;
  display: inline-block;
  font-size: 14px;

  @include mqMaxWidth($screenXs) {
    max-width: 100%;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .headerTitle {
    font-size: 20px;
    font-family: $titleFontFamily;
    line-height: 25px;
  }
}

.searchWrp {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1;
}
