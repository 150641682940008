.filters {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
}

.actionButton {
  margin-right: 0;
}

.tableWrapper {
  padding: 20px;
}

.formField {
  margin-bottom: 0;
  max-width: 140px;
}

.statusIndicator {
  float: none;
}

td.statusCell {
  height: 57px;
}
