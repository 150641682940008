@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.dealTooltipContainer {
  width: 100%;
  margin-bottom: 30px;
}

div.dealOptionContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  .badge {
    border: 1px solid $ColorGrayscaleGray3;
    border-radius: 2px;
    padding: 2px 5px;
    font-family: $fontFamily;
    color: $ColorGrayscaleGray2;
    font-size: 12px;
    line-height: 12px;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.attachmentsFieldContainer {
  margin-bottom: 30px;

  [data-test="uploadDropzone"] {
    padding: 0;
  }
}

.pageWrapper {
  margin-top: 58px;
}
