@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.statusLabel {
  margin-right: 25px;
}

.statusIndicator {
  height: 12px;
  width: 12px;
  display: inline-block;
  position: absolute;
}

.statusCircleBig {
  height: 12px;
  width: 12px;
  border-radius: 10px;
  border-width: 4px;
}

.liveBanner {
  background: $verifiedColor;
  border-color: $verifiedColor;
}

.notLiveBanner {
  background: $secondary3Color;
  border-color: $secondary3Color;
}
