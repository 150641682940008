@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.field {
  display: flex;
  padding: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-radius: 3px;
  border: 1px solid $secondary2Color;

  > div {
    display: flex;
    align-items: center;

    *:not(:first-child) {
      margin-left: 10px;
    }
  }
}

.label {
  color: $secondary3Color;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 7px; /* 70% */
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.domain {
  color: $titleColor;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
}

.price {
  color: $secondary3Color;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px; /* 141.667% */
}
