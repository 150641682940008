@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/condor/ui/common/Layout/variables.scss";

.form {
  max-width: 100%;
}

.inputFieldsContainer {
  max-width: $formFieldMaxWidth;
}

.verifiedCompanyNameInputField {
  color: $verifiedColor;
}

.accountStateLabel {
  margin-bottom: 15px;
}

.mappingsPage {
  background-color: transparent;
  box-shadow: none;
  margin-bottom: 0;
}

.accountProfileHeader {
  position: relative;
  width: 100%;
  height: 36px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accountAddressArea {
  height: 108px;
  overflow: auto;
}

.currencyWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.currency {
  width: 108px;
}

.aumInput {
  max-width: 378px;
  width: 100%;
}
