.wrp {
  display: table;
  width: 100%;
  height: 100%;
}

.aligner {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
