@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.button {
  width: 36px;
  margin-right: 0;
  padding: 0;
  border-radius: 0;
  border: 0;

  i {
    fill: $actionColor;

    &:last-child {
      display: none;
    }
  }

  &:hover {
    i {
      &:first-child {
        display: none;
      }

      &:last-child {
        display: inline-block;
      }
    }
  }
}
