@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.roadshowInput {
  margin-top: 30px;
  flex: 1;
  width: 343px;
}

.nameCell {
  width: 40%;
}

.actionsCell {
  display: flex;
  justify-content: space-between;
  flex: 1;
  svg {
    display: block;
    fill: $blue;
  }
}

.roadshowInputWrapper {
  display: flex;
}

.roadshowSubmit {
  margin-top: 30px;
  margin-left: 10px;
}

.table {
  margin: 0 -20px;
}
