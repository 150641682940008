.startedAt,
.finishedAt {
  display: inline-block;
}

.divider {
  display: inline-block;
  padding: 0 15px;
  background-color: #e5e5e5;
  border: 1px solid #dcdcdc;
  line-height: 34px;
}

.timeZoneName {
  display: inline-block;
  padding-left: 6px;
  line-height: 34px;
}
