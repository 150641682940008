@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.panelHeaderLabel {
  margin: 0 0 20px;
}

.removedConferenceIdModal {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.removedConferenceIdWarningContainer {
  background-color: $disclaimerColor;
  padding: 10px;
}

.roadshowInputsContainer {
  display: flex;
  gap: 20px;
}

.conferenceInputsContainer {
  @extend .roadshowInputsContainer;
}

.inputField {
  width: 495px;
}

.removeFooterCheckbox {
  margin-top: 30px;
}

.linkIconContainer {
  position: relative;
  width: 16px;
}

.linkIcon {
  fill: $secondary2Color;
  position: absolute;
  top: 33px;
}

.footerHiddenCheckboxLabelDisabled {
  color: $secondaryColor;
}

.explanationLabelIconContainer {
  position: relative;
  top: 2px;
  margin-left: 5px;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;

    &:hover {
      fill: $titleColor;
    }
  }
}

.questionIconContainer {
  margin-left: 4px;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;
    position: relative;
    top: 2px;

    svg {
      vertical-align: top;
    }

    &:hover {
      fill: $titleColor;
    }
  }
}

.linkToDrsField {
  width: 470px;
  margin: 10px 0 0 25px;
}

.replayRadioBtn {
  width: 495px;

  &:not(:last-child) {
    margin: 0 0 10px;
  }
}
