@import "@/Framework/GlobalStyles/variables.scss";

.container {
  display: inline-block;
  padding: 0 4px;
  min-height: 13px;
  border-radius: 2px;
  font-family: $fontFamily;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 13px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
}
