.companyCreateModal {
  :global(.modalDialog) {
    max-width: 600px;
  }
}

.migrateContent {
  .migrateTitle {
    margin-bottom: 30px;
  }
}
