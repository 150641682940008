@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/components/Clearfix/clearfix.scss";

.filterItem {
  display: table;
  width: 100%;
  margin-bottom: 3px;

  &:hover {
    .filterItemBtnOnly {
      visibility: visible;
    }
  }
}

.filterNestedWrp {
  padding: 2px 0;
}

.filterNestedItem {
  .filterItemCheckbox {
    padding-left: 28px;
  }
}

.filterItemCheckbox {
  display: table-cell;
  width: 280px;
  padding-bottom: 1px;
  padding-top: 3px;
  padding-left: 3px;

  label {
    display: block;
  }

  &:hover {
    background-color: #f9f9f9;
  }
}

.filterItemCount {
  padding-left: 10px;
  padding-right: 5px;
  text-align: right;
  display: table-cell;
  font-size: 11px;
  color: $secondaryColor;
  width: 50px;
  white-space: nowrap;
}

.filterItemCountSelected {
  color: $titleColor;
}

.filterItemExpand {
  display: table-cell;
  width: 26px;
}

.filterColumnsWrp {
  display: table;
  width: 100%;

  @include mqMaxWidth(568px) {
    display: block;
  }
}

.filterColumn {
  display: table-cell;

  @include mqMaxWidth(568px) {
    display: block;
  }
}

.filterSearchInput {
  margin-bottom: 20px;
}

.filterSearchZerocase {
  color: $secondaryColor;
}

.filterRangeSlider {
  display: block;
  margin-bottom: 10px;
}

.filterRangeInput {
  width: 100%;
  @include clearfix;

  + .filterColumnsWrp {
    padding-top: 15px;
  }
}

.filterRangeInputMin {
  float: left;

  input {
    width: 114px;
    min-width: 114px;
  }
}

.filterRangeInputMax {
  float: right;

  input {
    width: 114px;
    min-width: 114px;
  }
}

.filterRadio {
  margin-bottom: 5px;
}

// PanelGroupStyles
.panelGroupWrapper {
  margin-bottom: 0;
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}

.panelGroupHeader {
  position: relative;
  background-color: $secondary5Color;
  padding: 12px 20px;
  padding-right: 40px;
  color: #2d2d2d;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  border-bottom: 1px solid #eee;

  &:hover {
    background-color: #eee;
  }
}

.panelGroupFilterHeaderArrowIcon {
  padding: 0;
  width: 12px;
  height: 12px;
  transition: none;

  i {
    fill: $actionColor;
    width: 12px;
    height: 12px;
  }
}

.panelGroupContentWrapper {
  padding: 20px 16px 20px 46px;
}

// Nested panelGroupStyles
.panelGroupFilterNestedItem {
  position: relative;
  box-shadow: none;
  margin-bottom: 0;

  &:hover {
    box-shadow: none;
    .panelGroupFilterNestedHeaderArrowWrapper {
      visibility: visible;
    }
  }
}

.panelGroupNestedHeader {
  position: relative;
  padding: 0;
  color: #2d2d2d;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    .panelGroupFilterNestedHeaderArrowWrapper {
      i {
        fill: $actionColorHover;
      }
    }
  }
}

.panelGroupFilterNestedHeaderArrowWrapper {
  cursor: pointer;
  visibility: hidden;
  padding: 0;
  margin: 0;
  width: 12px;
  top: 4px;
  right: 4px;

  .panelGroupFilterNestedHeaderArrowIcon {
    padding: 0;
    width: 12px;
    height: 12px;
    transition: none;

    i {
      fill: $actionColor;
      width: 12px;
      height: 12px;
    }
  }
}

.panelGroupFilterNestedContent {
  padding: 0;
}
