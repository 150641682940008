@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.permissionsContainer {
  margin: -20px;

  > div:first-child {
    padding: 20px 20px 0;
  }
}

.tableContainer {
  margin-bottom: 20px;
}

.descriptionRow {
  color: $secondaryColor;
}

.tabsContainer {
  margin-left: 20px;
}

.checkBoxRow {
  label {
    padding-right: 23px;
  }
}

.actionsContainer {
  padding: 0 20px 20px;
}

.tableWrapper {
  overflow: auto;
}
