@import "@dealroadshow/uikit/core/styles/variables/colors";

.articlesModal {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.modalDescription {
  margin: 0;
}

.modalTable {
  margin-top: 20px;
  background-color: $ColorGrayscaleGray6;
  padding: 20px 20px 10px;
}

.modalRow {
  display: flex;
  + .modalRow {
    margin-top: 10px;
  }

  p {
    margin: 0;
  }
}

.modalTableTitle {
  width: 114px;
  min-width: 114px;
  text-transform: uppercase;
  color: $secondary3Color;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 20px;
}

.modalTableHeadline {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: $ColorGrayscaleBlack;
}

.modalTableAuthor {
  color: $ColorGrayscaleBlack;
}
.modalTableTags {
  display: flex;
  flex-wrap: wrap;
}

.modalTableTag {
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  color: $baseTextColor;
  background-color: $btnDefaultColor;
  margin-right: 5px;
  margin-bottom: 5px;
}

.icon {
  display: inline-block;
  fill: #fff;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
