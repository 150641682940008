@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.select {
  :global(.Select-menu-wrapper) {
    width: 226px;
    position: absolute;
    border-top: 1px solid #8c8c8c;
    margin-top: -1px;
    z-index: 1;
  }
}

.selectIcon {
  fill: $actionColor;
  color: $actionColor;
  margin-right: 10px;
}
