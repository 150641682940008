@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.unsubscribed {
  border-color: $unsubscribedColor;
  background-color: $unsubscribedColor;
}

.subscribed {
  border-color: $verifiedColor;
  background-color: $verifiedColor;
}

.unsubscribeIcon {
  fill: $actionColor;
  transition: 0.3s ease;

  &:hover {
    fill: $actionColorHover;
  }
}

.subscriptionTooltipTable {
  text-align: left;
}

.subscriptionRowName {
  padding-right: 10px;
}

.noWrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 130px;
  max-width: 130px;
  overflow: hidden;
}

.updatedAt {
  width: 165px;
}

.weeklyCell {
  @extend .noWrap;
  width: 330px;
  min-width: 230px;
}
