@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.buttonWithIcon {
  padding: 0 10px;
  i {
    min-width: 16px;

    svg {
      margin-top: 4px;
      fill: $actionColor;
    }
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }
}
