@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

@mixin filledCircle {
  border-width: 4px;
}

.activatedContact {
  @include filledCircle;
  background: $verifiedColor;
  border-color: $verifiedColor;
}

.unactivatedContact {
  @include filledCircle;
  border-color: $unverifiedColor;
}

.bannedContact {
  @include filledCircle;
  background: $bannedColor;
  border-color: $bannedColor;
}

.verifiedInvestor {
  border-color: $verifiedColor;
}

.defaultStatus {
  border-color: $secondary3Color;
}

.unverifiedInvestor {
  @include filledCircle;
  border-color: $unverifiedColor;
}

.bannedInvestor {
  @include filledCircle;
  border-color: $bannedColor;
}

.verifiedCompany {
  @include filledCircle;
  color: $verifiedColor !important; //!important is required in order to set the className for select option

  span {
    color: $verifiedColor !important;
  }

  &:hover {
    color: #000 !important;
  }
}
