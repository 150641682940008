@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.searchFilterInput {
  width: 385px;
  float: right;

  @include mqMaxWidth($screenXs) {
    width: 100%;
    float: left;
  }

  .inputClassName {
    padding-right: 10px !important;
  }
}

.tableWrapper {
  padding: 20px;
}
