@import "@/Framework/GlobalStyles/variables.scss";
@import "@/condor/ui/common/Layout/variables.scss";

.verifiedCompany {
  color: $verifiedColor;
}

.dataTableContainer,
.dataTableFormWrp {
  position: static;
}

.dateCol {
  min-width: 110px;
  width: 110px;
  padding-right: 0;

  &:last-child {
    min-width: 140px;
    width: 140px;
  }
}
