@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.cardHeader {
  padding: 20px 20px 10px;
}

.cardContainer {
  min-height: calc(100vh - 99px);
  display: flex;
  flex-direction: column;
}

.tabs {
  border-bottom: 1px solid $secondary2Color;
  padding: 0 20px;
}

.tabTitle {
  text-transform: capitalize;
}

.buttonIcon {
  margin-left: 10px;
}
