@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tableRowActions {
  line-height: 0;
}

.actionContainer {
  display: inline-block;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.action {
  fill: $actionColor;

  &:hover {
    cursor: pointer;
    fill: $actionColorHover;
  }
}

.disabledAction {
  fill: $secondaryColor;

  &:hover {
    cursor: default;
    fill: $secondaryColor;
  }
}
