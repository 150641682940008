@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.filterWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > div:first-child {
    @include mqMaxWidth($screenXs) {
      width: 100%;
    }
  }
}

.checkboxFiltersContainer {
  display: flex;
  justify-content: flex-end;
  padding-top: 5px;
  @include mqMaxWidth($screenXs) {
    width: 100%;
  }
}

.traceHeader {
  @include mqMaxWidth($screenXs) {
    display: flex;
    flex-wrap: wrap;
  }
}

.label {
  @include mqMaxWidth($screenXs) {
    display: flex;
    flex-wrap: wrap;
  }
}

.updated {
  font-size: 11px;
  display: flex;
  justify-content: flex-end;
  color: $secondary3Color;
  margin-right: 30px;
}

.tableHeaderWrapper {
  padding: 20px 20px 0;
  > div > div:last-child {
    @include mqMinWidth($screenXs) {
      width: 330px;
    }
  }
}

.toolbar {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tabs {
  margin-top: -25px;
  padding-left: 20px;
}
