.modal {
  :global(.modalDialog) {
    max-width: 600px;
    transform: none;
  }
}

.migrateContent {
  .buttonsWrp {
    position: static;
  }
  .migrateTitle {
    margin-bottom: 30px;
  }
}
