@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.searchFilterInput {
  width: 385px;
  float: right;

  @include mqMaxWidth($screenXs) {
    width: 100%;
    float: left;
  }

  .inputClassName {
    padding-right: 10px !important;
  }
}

.filtersContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.productsFilter {
  margin: 0 20px 0 0;
  padding: 0;
  width: 260px;
}

