@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.wrap {
  position: relative;
}

.toolbar {
  margin: 10px 0;
}

.active {
  fill: #fff;
  background: $secondary3Color;
}

.editableWrp {
  border: 1px solid $secondary2Color;
  padding: 10px;
  align-items: center;

  p {
    margin: 0;
  }
}

.editableWrpValidation {
  border: 1px solid $cancelColor;
}

.editable {
  &:focus-visible {
    outline: none;
  }
}

.matchingText {
  color: $secondaryColor;
  background: none;
}

.textareaCharacters {
  margin: 0;
  font-size: 11px;
  line-height: 15px;
  color: $secondaryColor;
  padding: 4px 0;
}

.warning {
  color: $cancelColor;
}

.arrow {
  display: none;
}

.popoverWrapper {
  padding: 0 !important;
}

.clearButton {
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 12px;
  border: 0;
  background-color: $secondaryColor;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    width: 6px;
    height: 6px;
    fill: #fff;
  }
}

.tooltip {
  width: 100%;
  display: block;
}
