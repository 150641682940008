.formGroup {
  .radioInput {
    margin-right: 0;
    margin-top: 10px;
    display: block;
  }
}

.label {
  margin-bottom: 0;
}

.description {
  margin-top: 20px;
  margin-bottom: 0;
  letter-spacing: 0.01em;
}
