@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/typography.scss";

.metadata {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.author {
  color: $ColorGrayscaleBlack;
  margin: 0;
}

.date {
  color: $ColorGrayscaleGray2;
  margin: 0 0 0 10px;
}

.title {
  margin: 20px 0;
  font-weight: 700;
  font-family: $titleFontFamily;
  font-size: 24px;
  line-height: 30px;
  color: $ColorGrayscaleBlack;
}

.tagItem {
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  color: $baseTextColor;
  background-color: $btnDefaultColor;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.description {
  margin: 0;
  overflow-x: auto;
}
