@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.ssoUploadForm {
  .ssoUploadFormTitle {
    color: $titleColor;
    font-family: $titleFontFamily;
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
  }

  .ssoTableWrapper {
    margin-left: -20px;
    margin-right: -20px;
  }

  .ssoUploadFormTable {
    > thead > tr {
      background-color: #fff;
      height: 46px;
    }

    > tbody > tr {
      background-color: $secondary5Color;
      border-top: 1px solid $defaultTableBorderColor;
      border-bottom: 0;
      height: 46px;

      &:hover {
        background-color: $defaultBackgroundColor;
      }

      .cellFileName {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        .cellFileNameContent {
          color: $actionColor;
          font-family: $fontFamily;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.14px;
        }

        .cellFileNameIcon {
          fill: $secondaryColor;
          flex: 0 0 16px;
        }
      }

      .cellUploadDate {
        color: $titleColor;
        font-family: $fontFamily;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.14px;
      }

      .cellActions {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .buttonRemove {
          cursor: pointer;
          fill: $actionColor;
        }
      }
    }
  }

  .ssoUploadZoneContainer {
    margin-top: 20px;

    .ssoUploadZoneItem {
      height: 108px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0;
    }

    .maxSizeTip {
      text-align: center;
      font-size: 11px;
      color: $secondaryColor;
      margin-top: 10px;
      line-height: 18px;
    }
  }
}

.deleteModal {
  :global(.modalDialog) {
    max-width: 450px;
  }

  .deleteButton {
    background-color: $cancelColor;

    &:hover {
      background-color: $cancelColorHover;
    }
  }

  .deleteMessageContainer {
    background-color: $disclaimerColor;
    padding: 10px;
    text-align: left;

    .fileName {
      font-weight: 700;
      word-wrap: break-word;
    }
  }
}

.viewModal {
  :global(.modalDialog) {
    max-width: 800px;
  }

  .viewModalContent {
    padding: 20px;
    background-color: $secondary5Color;

    p {
      color: $baseTextColor;
      font-family: "DM Mono";
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.13px;
      margin: 0;
    }
  }
}
