@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.previewBlock {
  flex: 1 0 auto;
  background-color: $secondary5Color;
  align-self: flex-start;
  margin-left: 45px;
  padding: 20px;

  @media screen and (max-width: 960px) {
    width: 100%;
    margin-left: 0;
  }

  .title {
    font-family: $fontFamily;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: $secondary3Color;
    letter-spacing: 1px;
    margin-bottom: 17px;
  }

  .previewItem {
    + .previewItem {
      margin-top: 13px;
    }
  }

  .copyIcon {
    width: 12px;
    fill: $actionColor;
    display: inline-block;
    margin-left: 5px;
    position: relative;
    bottom: -2px;
  }
}
