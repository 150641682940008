.action {
  color: #333;
  font-size: 23px;
  margin-right: 5px;
  cursor: pointer;
  text-decoration: none;
}

.isDisabledAction {
  cursor: default;

  i {
    fill: #acacac;
  }
}
