@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/card.scss";

.tableFilters {
  position: absolute;
  right: 10px;
  top: 20px;
  display: flex;

  @include mqMaxWidth($screenL) {
    right: auto;
    left: 20px;
  }
}

.name {
  white-space: nowrap;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actionWrapper {
  margin-right: 20px;
}

.icon {
  display: inline-block;
  fill: #fff;
  position: relative;
  top: 3px;
  margin-right: 10px;
}
