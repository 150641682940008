@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.cardContainer {
  padding: 22px 20px;

  .title {
    margin-bottom: 30px;
  }
}

.formWrapper {
  max-width: 814px;
  width: 100%;
}

.warning {
  width: 100%;
  background-color: $backgroundHighlightColor;
  box-sizing: border-box;
  padding: 11.5px 10px;
  color: $baseTextColor;
  font-size: 11px;
  display: flex;
  flex-direction: row;
  align-items: center;

  i {
    width: 12px;
    height: 12px;
    fill: $secondaryColor;
    margin-right: 10px;
  }

  &FullWidth {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

.row {
  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;

    &:first-child {
      margin-right: 10px;
    }
  }

  @include mqMaxWidth($screenS) {
    flex-direction: column;

    > div {
      width: 100%;
      margin-right: 0;
    }
  }
}

.description {
  height: 108px;
}

.buttonsWrapper {
  margin-top: 20px;
}

.discardModal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}
