@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tagWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $secondary5Color;
  border-radius: 3px;
  padding: 1px 8px;
  margin-right: 6px;
  margin-bottom: 6px;
  font-size: 12px;
  color: $baseTextColor;

  &.isNotValid {
    background-color: #fbd8db;
    color: $cancelColor;
  }
}

.removeTagIconWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 16px;
  height: 16px;
  margin-left: 4px;
  margin-right: -4px;

  &:hover {
    .removeTagIcon svg {
      fill: $actionColorHover;
    }
  }
}

.removeTagIcon {
  width: 8px;
  height: 8px;

  svg {
    width: 8px;
    height: 8px;
    fill: $actionColor;
  }
}
