.dealSlidesTable {
  width: 70%;

  th,
  td {
    border-right: 1px solid #dcdcdc;
  }

  td {
    &.previewCell {
      max-height: 156px;
      position: relative;
      height: 156px;
      min-width: 138px;
      max-width: 300px;

      img {
        max-height: 100%;
        max-width: 100%;
        width: auto;
        height: auto;
      }
    }

    &:last-child {
      border-right-width: 0;
    }
  }
}

