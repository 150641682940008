@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.formWrapper {
  width: 494px;

  @media screen and (max-width: 960px) {
    width: 100%;
    margin-bottom: 20px;
  }

  .domainsField {
    margin-bottom: 30px;
  }
}

.label {
  display: block;
  text-transform: uppercase;
  font-family: $fontFamily;
  color: $secondary3Color;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
  margin-bottom: 10px;
}

.inputTagsWrapper {
  position: relative;
}

.error {
  position: absolute;
  text-align: left;
  font-size: 11px;
  line-height: 20px;
  color: $cancelColor;
  left: 0;
  margin: 0;
  bottom: -20px;

  + .error {
    bottom: -30px;
  }
}

.checkboxWrapper {
  margin-bottom: 10px;
}

.formButtonsContainer {
  position: absolute;
  bottom: -85px;
  left: 0;
  padding-bottom: 20px;

  button {
    display: inline-block;
  }
}

.hiddenCheckbox {
  visibility: hidden;
  position: absolute;
}

.successBtn {
  min-width: 100px;
}

.saveAndCreateButton {
  background-color: $successColor;
  &:hover {
    background-color: $successColorHover;
  }
}
