.dataTableContainer {
  margin-left: -20px;
  margin-right: -20px;

  tbody {
    tr {
      background-color: #f9f9f9;

      td {
        padding-top: 13px;
        padding-bottom: 13px;
      }

      &:hover {
        background-color: #f3f3f3;

        .actionButtonsCellContent {
          opacity: 1;
        }
      }
    }
  }
}

.paginator {
  padding-top: 20px;
}

.search {
  margin-bottom: 10px;
}

.actionButtonsCellContent {
  opacity: 0;

  .button {
    width: 16px;
    height: 16px;

    i {
      top: 0;
    }
  }
}

.cellData {
  word-break: break-word;
}
