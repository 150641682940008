@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.bondsListHeader {
  border-bottom: 1px solid $secondary2Color;
}

.bondsListNavigation {
  margin: 13px 20px;
}

.deleteIcon {
  display: inline-block;
  opacity: 0;
  float: right;
  width: 100%;
  cursor: pointer;

  i {
    fill: $actionColor;

    &:hover {
      fill: $actionColorHover;
    }
  }
}

.row:hover {
  .deleteIcon {
    opacity: 1;
  }
}
