@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tagsInputWrapper {
  flex-grow: 1;
  padding: 1px 0;

  &.isDisabled {
    display: none;
  }
}

.tagsInput {
  width: 100%;
  height: 100%;
  outline: 0;
  border: 0;
  color: $titleColor;
  padding: 1px 0 8px;

  &:disabled {
    background-color: transparent;
  }

  &::-ms-clear {
    display: none;
  }
}
