@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.hideOnRowHoverHelper {
  display: block;
}

.showOnRowHoverHelper {
  display: none;
}

.tableWrapperContainer {
  padding: 10px 10px 0 20px;

  @include mqMaxWidth($screenXs) {
    padding: 10px 20px 0;
  }
}

.tableWrapperContent {
  table tbody tr {
    position: relative;

    &:hover {
      .hideOnRowHoverHelper {
        display: none;
      }

      .showOnRowHoverHelper {
        display: block;
      }
    }
  }

  td {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: $titleColor;
    height: 47px;
  }
}
