@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.buttonsWrp {
  display: flex;
  justify-content: space-between;
}

.createBtn {
  padding: 0;
  display: flex;
  align-items: center;
}

.addIcon {
  fill: $actionColor;
  height: 16px;
  width: 16px;
  margin-right: 10px;
}
