@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

$pendingColor: #fdca2c;

.tableRow {
  tr {
    .link {
      display: none;
      width: 12px;
      height: 12px;
      fill: $actionColor;
      margin-left: 10px;
    }

    &:hover {
      .link {
        display: inline-block;
      }
    }
  }
}

.statusLabel {
  margin-left: 20px;
}

.disable {
  background-color: $cancelColor;
}

.pending {
  background-color: $pendingColor;
}

.sent {
  background-color: $successColor;
}
