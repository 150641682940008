@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.dataTableContainer {
  overflow: auto;
}

.dataroomTable {
  margin-top: 10px;

  tbody tr,
  thead tr {
    height: 46px;

    td {
      color: $titleColor;
    }

    th:last-of-type,
    td:last-of-type {
      padding-right: 10px;
    }
  }
}

@include mqMaxWidth($screenXs) {
  .hideTooltipOnMobile {
    display: none;
  }
}

