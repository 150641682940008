@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.modalContainer {
  :global(.modalDialog) {
    max-width: 800px;
    position: relative;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  position: relative;
}
