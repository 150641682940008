@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.attachmentLabel {
  display: inline-block;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $ColorGrayscaleGray2;
  line-height: 10px;
  margin: 0 0 10px;
}

.attachmentItem {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: $ColorGrayscaleGray6;
  margin: 0;
  padding: 12px 10px;
  border-top: 1px solid $ColorGrayscaleGray5;
}

.attachmentItemName {
  margin: 0 0 0 10px;
  font-size: 14px;
  color: $ColorPrimaryBlue;
}

.previewModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
