@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.cellRedColor {
  color: $cancelColor;
}

.cellGreenColor {
  color: $successColor;
}

.cellDefaultColor {
  i {
    margin-left: 10px;
  }
}

.tooltipContainer {
  text-align: left;
}

.buttonsContainer {
  padding: 20px;
}

.tableWrapper {
  margin-bottom: 20px;
}

.tableContent {
  height: 100%;
}

.tableContainer {
  height: calc(100% - 140px);
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow: auto;
}

.dataTable {
  thead th {
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 3;
  }

  th:first-child {
    position: sticky;
    left: 0;
    z-index: 4;
  }

  tbody td {
    padding-top: 15px;
    padding-bottom: 15px;
    height: auto;
  }

  tbody td:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    background-color: #fff;
  }

  tbody tr:hover td:first-child {
    background-color: $secondary5Color;
  }

  tbody tr:hover {
    .plusCircleIcon {
      display: inline-block;
    }
  }

  @include mqMaxWidth($screenS) {
    min-width: 450px;
  }
}

.plusCircleIcon {
  display: none;
  fill: $actionColor;
  margin-top: -2px;

  &:hover {
    cursor: pointer;
    fill: $actionColorHover;
  }
}
