@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.formButtonsContainer {
  position: absolute;
  bottom: -80px;
  left: 0;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  button {
    display: inline-block;
  }
}

.previewAndPublishButton {
  background-color: $successColor;
  &:hover {
    background-color: $successColorHover;
  }
}

.unpublishButton {
  color: $red2;
  margin: 0;
  padding: 0 10px;

  &[disabled] {
    margin: 0;
    padding: 0 10px;
  }

  &:hover {
    color: $red;
  }
}
