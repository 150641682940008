.attachmentsFieldContainer {
  margin-bottom: 30px;

  [data-test="uploadDropzone"] {
    padding: 0;
  }

  .dropzoneContainer {
    padding: 36px 0 34px;
    height: 108px;
  }
}
