.modal {
  .modalDialog {
    max-width: 800px;
  }
}

.modalHeading {
  background-color: #fff7de;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.addNewForm {
  margin-bottom: 10px;
}

.modalHeadingLabel {
  color: #8c8c8c;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  margin-right: 40px;
}

.modalHeadingValue {
  color: #2d2d2d;
  letter-spacing: 0.01em;
  word-break: break-word;
}
