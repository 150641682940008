@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.deleteModal {
  :global(.modalDialog) {
    max-width: 450px;
  }
}

.deleteButton {
  background-color: $cancelColor;

  &:hover {
    background-color: $cancelColorHover;
  }
}

.deleteMessageContainer {
  background-color: $disclaimerColor;
  padding: 10px;
  text-align: left;
  word-break: break-word;

  b {
    font-weight: 700;
  }
}
