@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.formField {
  width: 100%;
  flex: 1;
  max-width: 378px;
}

.addCuePoints {
  display: inline-block;
  vertical-align: top;
  position: relative;
  height: 32px;
  width: calc(100% - 36px);
}

.addCuePointsInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.addCuePointsButton {
  &,
  &[disabled] {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding: 10px;
    margin-right: 0;
    border: 0;
  }

  i {
    display: block;
    svg {
      fill: #fff;
    }
  }
}

.cuePointsTimeField {
  margin-bottom: 0;
}

.displayTextField {
  text-align: left;
  margin-bottom: 0;
  position: relative;
  max-width: 810px;
  width: 100%;
  background-color: #fff;
}

.displayTextEditableWrp {
  min-height: 40px;
  max-height: 80px;
  overflow: auto;
  padding: 10px 22px 10px 10px;
}

.timeCellHeader {
  display: flex;
  align-items: center;
  i {
    margin-left: 6px;
    svg {
      fill: $blue;
    }
  }
}

.actionsIcon {
  cursor: pointer;
  svg {
    fill: $blue;
  }
}

.tableContainer {
  margin: 0 -20px;
  overflow: auto;
}

.tableRow {
  tbody {
    tr {
      vertical-align: text-top;

      td {
        padding: 5px 9px;
      }
    }
  }
}
