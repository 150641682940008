@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.customSectionDetailsTable {
  thead tr th,
  tbody tr td,
  tfoot tr td {
    &:first-child {
      padding-left: 46px !important;
    }
  }

  thead tr th {
    background-color: $secondary5Color;
  }
}

.row {
  &:hover {
    .deleteRowBtn {
      display: block;
    }
  }
}

.deleteRowCell {
  width: 60px;
  position: relative;
}

.actionBtn {
  svg {
    fill: $actionColor;
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }
}

.deleteRowBtn {
  @extend .actionBtn;
  display: none;
  padding: 0;
  margin: 0;
  top: 0;
  right: 5px;
  position: absolute;
}

.addButton {
  @extend .actionBtn;
  margin: 15px 0 10px 25px;
}

.addIcon {
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}
