@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.actionButton {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  button {
    width: 16px;
    height: 16px;
  }
}

.desktopActions {
  display: none;

  @include mqMinWidth($screenM) {
    display: inline;
  }
}

.mobileAction {
  display: inline;

  @include mqMinWidth($screenM) {
    display: none;
  }
}

.dropdownContent {
  width: 200px;
}

.dropdownMenuButton {
  width: 12px;

  i {
    top: 0;
    margin-right: 0;
    width: 12px;
    height: 12px;
  }
}
