@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/components/Input/input.scss";

.dropzoneContainer {
  padding: 36px 0 34px;
  height: 108px;
}

.uploadPreviewContainer {
  height: 108px;
  max-height: 108px;
  min-width: 108px;
}

.uploadedFileNameContainer {
  max-width: 62px;
}

.disabledWrp {
  overflow: auto;
}

.disabledUploadPreviewContainer {
  position: relative;
  background-color: #fff;
  min-height: 106px;
  height: 106px;
  max-height: 106px;
  min-width: 106px;
  border-right: 1px dashed $secondary2Color;
}

.disabledContainer {
  height: 108px;
  display: flex;
  width: 100%;
  border-radius: 3px;
  border: 1px dashed #dadada;
  gap: 3px;
  text-align: center;
  background-color: $secondary5Color;
}

.disabledTextContainer {
  flex-grow: 1;
  padding: 36px 0 34px;
  display: flex;
  flex-direction: column;
  gap: 3px;
}

.disabledDragAndDropLabel {
  color: $secondary3Color;
}

.disabledFileFormatsLabel {
  font-size: 11px;
  color: $secondaryColor;
}

.error {
  border-color: $errorBorderColor;
}

.maxSizeTip {
  text-align: center;
  font-size: 11px;
  color: $secondaryColor;
  line-height: 18px;
}
