@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/components/Button/button.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/condor/ui/common/Layout/variables.scss";

.section {
  position: relative;
  margin-bottom: 50px;
  max-width: $formFieldMaxWidth;

  &:last-child {
    margin-bottom: 0;
  }
}

.select {
  padding-right: 120px;
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
}

.generateBtn {
  position: absolute;
  top: 43px;
  right: 0;
  margin: 0;
  background-color: $actionColor;
}

.textArea {
  margin-bottom: 25px;
}

.filledTextArea {
  textarea {
    height: 120px;
  }
}

.copyBtnContainer {
  display: inline-block;

  .copyBtn {
    @extend .action;
    @extend .disabled;
    margin-right: 15px;

    i {
      display: none;
    }
  }

  .copied {
    @extend .btn;
    @extend .action;
    @extend .disabled;

    margin-right: 15px;

    i {
      margin: 10px 0 0 8px;
      fill: #fff;
      width: 10px;
      height: 10px;
      float: right;
    }
  }
}

.invalidateBtn {
  background: $cancelColor;

  &:hover {
    background-color: darken($cancelColor, 15%);
  }

  &:active {
    background-color: darken($cancelColor, 15%);
  }
}
