@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.apiKeyInput {
  max-width: 449px;
}

.refreshButton {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid $secondary2Color;
  cursor: pointer;
}

.refreshIcon {
  fill: $actionColor;
}

.tooltipContainer {
  display: block;
  height: 100%;
}

.submitButton {
  margin-right: 30px;
}
