@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$topOfPagePC: 98px;
$topOfPageMobile: 178px;

.cardContainer {
  min-height: calc(100vh - $topOfPagePC);
  display: flex;
  flex-direction: column;

  @include mqMaxWidth($screenS) {
    min-height: calc(100vh - $topOfPageMobile);
  }
}

.actions {
  float: right;
}
