@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.head {
  display: flex;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: $secondary3Color;
  line-height: 10px;
  margin-bottom: 8px;
  padding: 0 20px;

  @include mqMaxWidth($screenS) {
    padding: 0;
  }
}

.underwriters {
  flex-grow: 1;
  max-width: 515px;

  @include mqMaxWidth($screenS) {
    padding-left: 20px;
  }
}

.lead {
  flex-basis: 65px;

  @include mqMaxWidth($screenS) {
    flex-basis: 81px;
  }
}
