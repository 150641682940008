.modal {
  :global(.modalDialog) {
    max-width: 885px;
  }
}
.modalData {
  font-size: 14px;
  line-height: 18px;
  margin: 0 0 20px;
}
