@import "@/condor/ui/common/Layout/variables.scss";

.companyProfile {
  .createDealButton {
    float: right;
    margin-right: 0;
  }

  .createDealIcon {
    top: 2px;
  }

  form {
    min-height: 120px;
  }
}

.inputFieldsContainer {
  max-width: $formFieldMaxWidth;
}

.verifiedCompanyNameInputField {
  color: $verifiedColor;
}

.accountStateLabel {
  margin-bottom: 15px;
}
