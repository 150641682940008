@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.wrapper {
  margin-bottom: 20px;
}

.item {
  margin-right: 35px;
}

.active {
  color: $titleColor;
  font-weight: 500;

  &:hover {
    cursor: default;
    color: $titleColor;
  }
}
