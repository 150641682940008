.formFileUploadOneFile {
  position: relative;
}

.formFileUploadOneFileStatus {
  position: absolute;
  left: 44%;
  top: 54%;
  display: inline-block;
  z-index: 3;
  color: #000;
}

.formFileUploadOneFilePercent {
  position: absolute;
  right: 5px;
  bottom: 10px;
  z-index: 3;
}

.formFileUploadOneFileName {
  position: absolute;
  left: 5px;
  bottom: 10px;
}

.trashIcon {
  position: absolute;
  right: 0;
  top: 0;
  background-color: #ccc;
  width: 25px;
  height: 25px;
  font-size: 20px;
  text-align: center;
  padding-top: 2px;
  cursor: pointer;
  z-index: 3;

  &:hover {
    background-color: #333;
    color: #fff;
  }
}

.progressBar {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  border-radius: 0;
  z-index: 3;
}

.progressBarFilled {
  border-radius: 0;
  z-index: 3;
}
