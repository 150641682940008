@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/UI/Templates/AppNavigation/appNavigation.scss";

.navItemTextWrapper {
  position: absolute;
  display: flex;
}


.navItemCounter {
  margin-left: 5px;
  align-self: center;
  background-color: $cancelColor;

  &.navItemCounterCollapsed {
    margin-left: 0;
    position: relative;
    top: -5px;
    @include mqMaxWidth($screenS) {
      top: 0;
      margin-left: 5px;
    }
  }
}
