@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.statusCell {
  position: relative;
}

.statusContainer {
  position: absolute;
  top: 17px;
  left: 35px;
}

.status {
  margin-bottom: 3px;
}

.expiredCallStatus {
  background-color: $gray9;
}

.liveCallStatus {
  background-color: $green;
}

.pendingCallStatus {
  background-color: $yellow3;
}

.callDateCell {
  width: 210px;
}

.filtersContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.radioFiltersContainer {
  margin: 0 5px 0 0;
}

.searchFilterInput {
  width: 470px;
}

.conferenceIdTooltipContainer {
  height: 12px;
}

.createGroupIcon {
  margin: 0 0 0 10px;
  fill: #fff;
  top: 3px;
}

.createGroupButton {
  margin: 0 10px 0 20px;
}

