.auditTrailTableWrapper {
  padding: 20px;
}

.exportButton {
  margin-right: 20px;
}

.exportButtonIcon {
  display: block;
  margin-top: 5px;
}
