@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.statusLabel {
  margin-left: 20px;
}

.statusText {
  text-transform: capitalize;
}

.statusDate {
  text-align: left;
  color: $secondaryColor;
}

.statusTimestamp {
  color: $secondary5Color;
}

.deleted {
  background-color: $placeholderColor;
}

.started,
.processing {
  background-color: $warningColor;
}

.processed {
  background-color: $successColor;
}

.error {
  background-color: $cancelColor;
}
