@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.typesContainer {
  display: inline-block;
  padding: 0 20px;
}

.buttonsTypes {
  padding: 10px 19px;
  outline: none;
}
