@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/components/Button/button.scss";

.addTrancheBtnContainer {
  width: 155px;
  text-align: left;
  position: relative;
  display: inline-block;
  background-color: #fff;
}

.addTrancheBtn {
  padding-top: 3px;

  &.disabled {
    cursor: default;
    color: #fff;

    .addTrancheIcon {
      fill: $secondary2Color;
    }

    &:hover {
      background-color: $secondary2Color;

      .addTrancheIcon {
        fill: $secondary2Color;
      }
    }
  }

  &:hover {
    .addTrancheIcon {
      fill: $actionColorHover;
    }
  }
}

.addTrancheLabel {
  position: absolute;
  margin-left: 10px;
  top: 0;
}

.addTrancheIcon {
  fill: $actionColor;
}
