@import "@/Framework/GlobalStyles/variables.scss";
@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.warning {
  background-color: $warningBackgroundColor;
  font-family: $fontFamily;
  color: $baseTextColor;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding: 15px 20px;
  margin-bottom: 20px;
}
