.panelHeaderLabel {
  margin: 0 0 20px;
}

.registrationEnabledCheckboxContainer {
  margin: 0 0 30px;
}

.positionLabel {
  margin: 0 20px 0 0;
}

.positionLastRadioButton {
  margin: 0;
}

.positionRadioButtonsContainer {
  display: inline-block;
}

.editorInput {
  border-radius: 3px;
  min-height: 108px;
}

.resetColorsBtn,
.resetColorsBtn[disabled] {
  padding: 0;
  margin: 20px 0 30px;
}

.underwritersLogosWrapper {
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 10px;
}

.underwritersLogosError {
  margin-top: -10px;
  padding: 0 20px;
}
