@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.iconsTool {
  border: 1px solid $defaultTableBorderColor;
  box-sizing: border-box;
  border-radius: 1px;
  padding: 8px 0 0;
  margin-right: 6px;
  width: 30px;
  height: 30px;
  text-align: center;
  cursor: pointer;

  i {
    width: 12px;
    height: 12px;
  }
}

.active {
  fill: #fff;
  background: $secondary3Color;
}
