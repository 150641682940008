@import "@/Framework/GlobalStyles/variables.scss";
@import "@/condor/ui/common/Layout/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.nameCell {
  color: $titleColor;

  &:hover {
    color: $titleColor;
  }

  &.isVerified {
    color: $verifiedColor;

    &:hover {
      color: $verifiedColor;
    }
  }
}

.deletedAccountField {
  color: $secondaryGreyColor;
}

.checkboxEditableCell {
  vertical-align: middle;
  text-align: center;
}
