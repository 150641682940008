@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/headers.scss";
@import "@/condor/ui/common/Layout/variables.scss";

.checkbox {
  margin-bottom: 10px;
}

.accountFieldContainer {
  position: relative;
  width: 100%;
}

.formSelect {
  max-width: $formFieldMaxWidth;
  width: 100%;
}

.addAccountBtn {
  position: absolute;
  display: inline-block;
  width: 36px;
  height: 36px;
  bottom: 0;
  right: 0;
  text-align: center;
  padding: 9px 0 5px;
  border-radius: 3px;
  border: 0;
  color: $btnDefaultColor;
  background-color: $actionColor;

  &.disabled {
    cursor: default;
    background-color: $secondary2Color;
    color: #fff;
  }
}

.plusIcon {
  fill: #fff;
}

.contactStatesLabel {
  margin-bottom: 15px;
}

.verifiedCompany {
  color: $verifiedColor !important; //!important is required in order to set the className for select option

  span {
    color: $verifiedColor !important;
  }

  &:hover {
    color: #000 !important;
  }
}

.twoFactorBlock {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.twoFactorInput {
  display: flex;
  border: 1px solid $secondary2Color;
  border-radius: 3px;
}

.twoFactorCode {
  font-size: 14px;
  line-height: 20px;
  padding: 8px 10px;
  min-width: 80px;
}

.twoFactorButton {
  border-left: 1px solid $secondary2Color;
  padding: 10px 10px 6px;
  cursor: pointer;
}

.refreshIcon {
  fill: $actionColor;
}

.refreshIconDisabled {
  fill: $secondary3Color;
}

.twoFactorDescription {
  font-size: 14px;
  line-height: 20px;
  color: $secondary3Color;
  margin-left: 30px;
}

.title {
  margin-top: 50px;
  margin-bottom: 30px;
}

.titleSmallMT {
  margin-top: 30px;
}
