@import "@/condor/ui/common/Layout/variables.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

$expandedRowsAmount: 10;
$tradeCellHeight: 30px;

.cellItem {
  vertical-align: top;
  white-space: nowrap;

  @include mqMinWidth($screenXs) {
    padding: 15px 20px !important;
  }
}

.mobileColName {
  display: none;
}

.expandedContainer {
  overflow-x: hidden;
  background: $secondary5Color;
  top: 36px;
  width: 100%;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;

  @include mqMaxWidth($screenXs) {
    flex-direction: column;
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    padding: 0 10px;
  }

  @include mqMinWidth($screenXs) {
    > div:first-of-type {
      padding-left: 20px;
    }

    > div:last-of-type {
      padding-right: 75px;
    }

    > div:not(:last-of-type) {
      margin-right: 10%;
    }
  }
}

.separator {
  border-bottom: 1px solid $gray6;
  width: 140%;
  position: absolute;
  top: 0;
  left: -25px;
}

.innerTableCol {
  > div {
    height: 30px;
    position: relative;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div:first-of-type {
      font-weight: 500;
      font-size: 10px;
      line-height: 7px;
      text-transform: uppercase;
      color: $secondaryGreyColor;
    }
  }
}

.tableContainer {
  :global {
    tr {
      &.isRowExpanded {
        background-color: #fff !important;
      }
    }
  }

  @include mqMaxWidth($screenXs) {
    overflow: visible;
    line-height: 18px;

    thead {
      display: none;
    }

    tbody {
      tr {
        display: flex;
        flex-direction: column;

        td,
        td:first-child {
          white-space: normal;
          width: 100% !important;
          height: auto;
          font-size: 13px;
          line-height: 18px;
          padding: 5px 0 5px 10px;

          > div {
            display: flex;
            justify-content: space-between;
            text-align: left;
            > div {
              flex: 1;
            }
          }
        }

        .dateTimeCell {
          > div {
            display: block !important;
          }
        }

        :global {
          tr {
            &.isRowExpanded {
              background-color: #fff !important;
            }
          }
        }
      }
    }

    .row {
      margin-bottom: 300px;
    }

    .mobileColName {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      width: 50%;
      font-size: 10px;
      line-height: 7px;
      text-transform: uppercase;
    }

    .expandCell {
      position: absolute;
      width: auto !important;
      right: 10px;
      padding: 0 !important;
      top: 15px;
      line-height: 12px;
    }

    .actionButtonsSection {
      @extend .expandCell;
      right: 35px;
    }
  }
}

.dateTimeCell {
  white-space: nowrap;
}

.mobileAdditionalOptionsTrigger {
  fill: $actionColor;
}

.hoveredContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  a {
    fill: $actionColor;
  }

  @include mqMaxWidth($screenXs) {
    display: none;
  }
}

.expandCollapse {
  cursor: pointer;
  fill: $actionColor;
}

.mapBtn,
.mapBtn:disabled {
  height: 28px;
  padding: 0 14px;
  margin: -6px -6px -6px 10px;
  line-height: 20px;
}

.mapBtnDisabled {
  @extend .mapBtn;
  cursor: default;
  background-color: $secondary2Color;
  color: #fff;

  &:hover {
    background-color: $secondary2Color;
  }
}

.issuerName {
  font-weight: 500;
}
