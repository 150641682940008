@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.inputsWrp {
  padding: 0;
  margin-top: 15px;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 1px 0;
  background-color: $secondary5Color;
  border-bottom: 1px solid $defaultTableBorderColor;

  &:first-child {
    border-top: 1px solid $defaultTableBorderColor;
  }

  &.noHighlight {
    justify-content: flex-start;
    background-color: transparent;
    border: 0;

    &:hover {
      background-color: transparent;
    }
  }

  &:hover {
    background-color: #ebebeb;

    .deleteButtonIcon {
      opacity: 1;
      visibility: visible;
    }
  }

  .inputWrapper {
    margin-left: 20px;
    flex-grow: 1;
    max-width: 495px;
    flex-basis: 495px;

    &.displayName {
      flex-basis: auto;
      margin-right: 20px;
    }

    @include mqMaxWidth($screenS) {
      margin-left: 0;
      padding-left: 20px;
    }
  }

  .inputField {
    margin-bottom: 0;
  }
}

.deleteButton {
  padding: 0 10px;
}

.deleteButtonIcon {
  opacity: 0;
  visibility: hidden;
  svg {
    fill: $actionColor;
    margin-top: 1px;
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }
}

.addMoreIcon {
  vertical-align: middle;
  margin-right: 8px;
  margin-top: -2px;
}

.addMoreButton {
  margin-top: 15px;

  svg {
    fill: $actionColor;
  }

  &:hover {
    svg {
      fill: $actionColorHover;
    }
  }

  &Disabled {
    font-weight: 500 !important;

    svg {
      fill: $secondaryColor;
    }

    &:hover svg {
      fill: $secondaryColor;
    }
  }

  @include mqMaxWidth($screenS) {
    padding-left: 20px;
  }
}

.checkboxContainer {
  flex-grow: 1;
  display: flex;
  padding: 0 20px;
  margin-top: 10px;
}
