@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.cardContainer {
  min-height: calc(100vh - 137px);
  display: flex;
  flex-direction: column;
}

.cardContainerHeader {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
}

.cardTitle {
  grid-column: 1 / -1;
}

.pageHeaderActions {
  margin-top: 5px;
}

.buttonIcon {
  margin-left: 10px;
}

.headerButtons {
  grid-row: 2 / 3;
  justify-self: start;
  align-self: end;
}

.cardHeader {
  padding: 20px 20px 10px;
  display: flex;
  justify-content: space-between;

  @include mqMaxWidth($screenXs) {
    flex-direction: column;
  }
}

.searchInputWrapper {
  max-width: 400px;
  width: 100%;
  margin-left: 10px;

  @include mqMaxWidth($screenXs) {
    margin-left: 0;
    margin-top: 20px;
  }
}

.name a {
  font-weight: 500;
  max-width: 500px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
