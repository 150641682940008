@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.spinner {
  display: inline-block;
  left: -42px;
  top: 5px;
}

.icon {
  fill: $actionColor;

  &.isDisabled {
    fill: $secondaryColor;
  }

  &:not(.isDisabled):hover {
    fill: $actionColorHover;
  }
}

.exportButton {
  background: transparent;
  border: 0;
}
