.headerButtons {
  margin-bottom: 20px;
}

.title {
  line-height: 30px;
  margin-bottom: 20px;
}

.disclaimerTextarea {
  resize: vertical;
  height: 180px;
  min-height: 60px;
  max-height: 500px;
}

.discardModal {
  :global {
    .modalDialog {
      max-width: 450px;
    }
  }
}
