@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.dataTableContainer {
  overflow: visible;
  margin: 0 -20px;
}

.rotatingEntryCodeWrapper {
  max-width: 120px;
}

.reviewTableContainer {
  margin-bottom: 30px;
}

.tableLablel {
  color: $secondaryColorHover;
  font-weight: 500;
}
