.select {
  width: 321px;
  margin-right: 10px;
  font-size: 12px;
}

.searchInput {
  width: 321px;
  margin-right: 10px;
}
