@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.sectorLabel {
  min-width: 42px;
}

.statusIndicator {
  display: inline-block;
}

.nameCell {
  display: inline-block;
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  white-space: nowrap;
}

.tagsCellTooltipContent {
  text-align: left;
  padding: 3px 4px;

  .tagsCellTooltipItem {
    margin: 0;
  }
}

.tagsCellItem {
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  padding: 4px 8px;
  color: $baseTextColor;
  background-color: $btnDefaultColor;

  + .tagsCellItem {
    margin-left: 5px;
  }
}

.column {
  display: table-cell;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

.cellWithTooltipLabel {
  @extend .column;

  width: 70px;

  a.link,
  .tooltip {
    display: flex;
  }

  .label {
    cursor: pointer;
    line-height: normal;
    padding: 1px 0 0;
    max-width: 42px;
    width: 42px;
  }
}
