.modal {
  &Footer {
    padding: 20px 20px 0;
  }

  :global {
    .modalDialog {
      max-width: 600px;
    }

    .modalBody {
      padding: 20px 0;
    }
  }
}
