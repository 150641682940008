@import "@dealroadshow/uikit/core/styles/variables/_common.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.container {
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  cursor: pointer;
  overflow: hidden;
}

.containerPremiumization {
  display: inline-flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  padding: 7px 10px;
  border-radius: 3px;
  max-height: 30px;
  cursor: pointer;
  max-width: 215px;

  i {
    fill: #fff;
  }

  @include mqMaxWidth($screenS) {
    // todo for Premiumisation release don't forget to set for .menuWrapper padding 20px and remove this
    margin: 5px -5px;
  }
}

.containerPremiumizationActive,
.containerPremiumization:hover {
  background-color: rgba(0, 0, 0, 0.7);

  .userPlan {
    opacity: 1;
  }
}

.avatar {
  min-width: 30px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: $actionColor;
  color: #fff;
  text-transform: capitalize;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
}

.name {
  font-size: 14px;
  line-height: 16px;
  text-align: left;
  color: rgba(255, 255, 255, 0.6);
  max-width: 100%;
  margin-left: 10px;

  span {
    display: block;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.namePremiumization {
  margin-left: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @include mqMinMaxWidth($screenS, $screenM) {
    display: none;
  }
}

.userPlan {
  display: flex;
  align-items: center;
  max-height: 16px;
  padding: 3px 3px 2px;
  border-radius: 2px;
  opacity: 0.5;
  border: 1px solid #fff;
  margin-left: 12px;

  span {
    font-size: 12px;
  }
}

.userIcon {
  display: flex;
  flex-shrink: 0;
}
