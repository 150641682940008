@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/styles/variables/_icons.scss";
@import "@dealroadshow/uikit/core/styles/helpers/align.scss";

.tableWrapper {
  margin-top: 18px;

  @include mqMaxWidth($screenXs) {
    margin-top: 0;
  }
}

.searchInputField {
  width: 350px;

  @include mqMaxWidth($screenM) {
    align-self: end;
    width: 280px;
  }

  @include mqMaxWidth($screenXs) {
    align-self: start;
    width: 100%;
  }
}

.userPlanWrapper {
  .userPlan {
    font-size: 12px;
    font-weight: 500;
  }
}

.email {
  color: $gray;
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
  margin: 0;
}

.dateCol {
  min-width: 110px;
  width: 110px;
  padding-right: 0;
  text-align: right;

  &:last-child {
    min-width: 140px;
    width: 140px;
  }
}

.fieldWrapper {
  display: flex;
  justify-content: space-between;
}


.topFieldLabel {
  display: flex;
  width: 77px;
  height: 36px;
  align-items: center;
}

.fromField {
  height: 36px;
  flex: 1;
  color: $titleColor;
}

.fromFieldLabel {
  @extend .topFieldLabel;
  align-items: flex-start;
}

.topInput {
  flex: 1;
  margin-bottom: 20px;
}

.fromFieldEmail {
  color: $secondaryColor;
}

.previewBlockTop,
.previewBlockBottom {
  padding: 20px 15px;
  line-height: 20px;
  background-color: $secondary5Color;
}

.previewBlockGreeting {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85px;
}

.previewBlockFooter {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85px;
}

.previewBlockMessage {
  padding: 15px;
  background-color: $backgroundHighlightColor;

  .titleMessage,
  .textMessage {
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.14px;
  }

  .textMessage {
    font-weight: 400;

    p {
      margin: 0;
      min-height: 20px;
      word-wrap: break-word;
    }
  }
}

.editor {
  div[contenteditable='true'] {
    min-height: 85px !important;
  }
}

.overlay {
  @extend .flexCenter;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);

  p {
    text-align: center;
  }

  i {
    width: $iconXL;
    height: $iconXL;
    svg {
      fill: $overlayIconColor;
    }
  }
}
