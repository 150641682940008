@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.dataTableWrapper {
  padding: 0;
}

.dataTable {
  thead {
    tr {
      &:hover {
        background-color: #fff !important;
      }

      th {
        height: 38px;
        padding: 10px;
      }
    }
  }

  tbody {
    tr {
      background-color: $secondary5Color;

      td {
        white-space: nowrap;
        padding: 0 10px;
        height: 38px;
      }

      &:hover {
        background-color: $defaultTableBorderColor !important;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
