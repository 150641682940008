@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.name {
  max-width: 780px;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search {
  @include mqMinWidth($screen568) {
    max-width: 400px;
  }
}

.tableRow {
  position: relative;
}

.statusIndicator {
  margin: 0 auto;
  display: block;
  float: none;
}

.dataTableContainer {
  flex-grow: 1;

  th.actionButtonsCell {
    position: absolute;
    pointer-events: none;
    left: 0;
  }

  td.actionButtonsCell {
    position: absolute;
    right: 0;
    opacity: 0;
    background-color: $ColorGrayscaleGray6;
  }

  th:nth-last-child(2),
  td:nth-last-child(2) {
    padding-right: 20px;
  }

  tbody {
    tr {
      td {
        padding: 13px 9px;
      }

      &:hover {
        td.actionButtonsCell {
          opacity: 1;
        }

        .hideOnHover {
          opacity: 0;
        }
      }
    }
  }
}

.actionButton {
  background: transparent;
  width: 16px;
  height: 16px;
  display: grid;
  margin: 0;

  &:hover {
    background: transparent;

    i {
      fill: $actionColorHover;
    }
  }

  &[disabled] {
    i {
      fill: $secondaryColor;
    }
  }

  &Tooltip {
    margin-left: 20px;
    display: inline-block;

    &Container {
      display: inline;
    }

    i {
      margin: 0;
      top: 0;
      fill: $actionColor;
    }
  }
}
