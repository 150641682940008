@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.inputsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.checkbox {
  padding: 11px 35px 0;
  font-size: 14px;
}

.searchInput {
  max-width: 385px;
  min-width: 300px;
  width: 100%;
}
