.modalWindow {
  :global {
    .modalDialog {
      width: 450px;
    }
  }
}

.spinnerStatus {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translate(0, 20px);
  display: block;
  padding: 0 20px;
  text-align: center;
  z-index: 3;
  color: #000;
}
