@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.pageLabel {
  @include mqMaxWidth($screenM) {
    display: block;
    width: 100%;
    float: none;
  }
}

.addItemInputWrapper {
  float: right;

  @include mqMaxWidth($screenM) {
    float: none;
    display: block;
    width: 100%;
    margin-top: 15px;
  }
}

.addItemInputContainer {
  display: inline-block;

  @include mqMaxWidth($screenM) {
    width: calc(100% - 48px);
  }
}

.addItemInput {
  width: 470px;
  height: 37px;

  @include mqMaxWidth($screenM) {
    width: 100%;
  }
}
.plusBtn,
.plusBtn[disabled] {
  vertical-align: top;
}

.filterInput {
  width: 340px;
  float: right;

  @include mqMaxWidth($screenS) {
    float: none;
    display: block;
    width: 100%;
  }
}
