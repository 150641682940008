@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.enableSSo {
  position: absolute;
  top: -40px;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label {
  font-family: $fontFamily;
  font-size: 10px;
  text-transform: uppercase;
  color: $secondary3Color;
  margin: 0 0 0 15px;
}
