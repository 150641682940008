@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.checkboxFiltersBlock {
  @include mqMaxWidth($screenS) {
    margin-bottom: 20px;
  }
}

.searchFilterInput {
  width: 354px;
  float: right;

  @include mqMaxWidth($screenXs) {
    width: 100%;
    float: left;
  }
}

.tableWrapper {
  padding: 20px;
}
