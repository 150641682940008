@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.linkIcon {
  float: left;
  position: relative;
  top: 9px;
  fill: $secondaryColor;
}

.inputContainer {
  display: block;
  padding-left: 28px;
}

.addBtn {
  margin-top: 15px;
}
