@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.headerComponentContainer {
  display: inline-block;
  float: right;

  a {
    vertical-align: top;
    margin: 0;
  }

  @include mqMaxWidth($screenS) {
    display: block;
    float: left;
    text-align: left;
    margin-top: 20px;
    width: 100%;
  }

  @include mqMaxWidth($screenXs) {
    margin-top: 0;

    a {
      margin: 20px 0 0;
    }
  }
}

.header {
  display: inline-block;
  padding-right: 20px;
}

.checkboxFiltersBlock {
  @include mqMaxWidth($screenS) {
    margin-bottom: 20px;
  }
}

.searchFilterInput {
  width: 350px;
  float: right;

  @include mqMaxWidth($screenXs) {
    width: 100%;
    float: left;
  }
}

