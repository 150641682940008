@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";

.content {
  padding: 0;
}

.innerContent {
  padding: 0;
}

.flexRow {
  display: flex;
  text-align: left;
  @include mqMaxWidth($screenM) {
    flex-direction: column;
  }
}

.flexColumn {
  width: 50%;
  padding: 50px;

  + .flexColumn {
    background: rgba(0, 0, 0, 0.025);
  }

  @include mqMaxWidth($screenM) {
    width: 100%;
    padding: 20px;
  }
}

.header {
  text-align: left;

  @include mqMaxWidth($screenM) {
    text-align: center;
  }
}

.logoWrp {
  margin-bottom: 50px;

  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
  }

  img {
   max-height: 36px;
  }

  @include mqMaxWidth($screenM) {
    margin-bottom: 20px;
  }
}

.title {
  margin: 0 0 10px;
}

.subtitle {
  color: $secondary3Color;
  font-size: 14px;
  font-family: $fontFamily;
  line-height: 20px;
  margin: 10px 0 30px;
  letter-spacing: 0.14px;
}

.row {
  display: flex;
  justify-content: space-between;

  @include mqMaxWidth($screenXs) {
    flex-direction: column;
  }
}

.column {
  width: calc(50% - 5px);

  @include mqMaxWidth($screenXs) {
    width: 100%;
  }
}

.select {
  text-align: left;
}

.explanationIconWrapper {
  position: relative;
  top: 2px;
  margin-left: 3px;
  display: inline-block;
}

.explanationIcon {
  width: 12px;
  height: 12px;

  svg {
    fill: $secondaryColor;
  }
}

.tooltip {
  width: 250px;
}

.optionalLabel {
  opacity: 0.5;
}
