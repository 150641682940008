@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.isCustomized {
  &True {
    margin-bottom: 30px;
  }

  &False {
    margin-bottom: 10px;
  }
}

.colorPicker {
  width: 365px;

  input {
    text-transform: uppercase;
  }

  &:last-child {
    margin-bottom: 10px;
  }
}
