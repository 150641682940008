@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";
@import "@dealroadshow/uikit/core/components/Button/button.scss";

.properties {
  // Reminder: don't add 'position: relative' attribute to this class in order not to break the stripe layout
  width: 195px;
  background-color: #fff;
  display: inline-block;
  padding: 10px 0;
}

.propertiesGroup {
  position: relative;

  &:last-of-type {
    .stripe {
      display: none;
    }
  }
}

.property {
  text-align: right;
  color: $secondaryColorHover;
  padding: 10px 20px;
  line-height: 30px;
  transition: all 200ms;

  &.collapsedProperty:not(:first-of-type) {
    display: none;
  }
}

.stripe {
  border-bottom: 1px solid $secondary2Color;
  width: 100%;
  position: absolute;
}

.expandIcon {
  position: absolute;
  left: 17px;
  top: 17px;
  cursor: pointer;
  svg {
    fill: $actionColor;
  }
}

.commentProperty {
  height: 182px;

  &.collapsedProperty {
    height: 43px;
  }
}

.propertyGroupTitle {
  display: flex;
  justify-content: flex-end;
  color: $titleColor;
  font-weight: 600;
  padding: 20px;
}
