@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.tranchesWrp {
  margin-bottom: -30px;
}

.tranchesContainer {
  display: flex;
  float: right;
  vertical-align: top;
  width: calc(100% - 195px);
  background-color: $secondary5Color;
  padding-right: 20px;
  padding-left: 20px;

  &.withLeftArrowButton {
    @extend .leftInnerShadow;
  }
}

.leftInnerShadow {
  box-shadow: inset 8px 0 8px -8px rgb(225, 225, 225);
}
