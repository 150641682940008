@import "@/Framework/GlobalStyles/variables.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.filtersBlock {
  display: inline-block;
  border: 1px solid $secondary2Color;
  border-radius: 3px;
  margin-right: 20px;
  height: 38px;
  vertical-align: top;
  white-space: nowrap;
  position: relative;

  &:last-child {
    margin-right: 0;
  }

  @include mqMaxWidth($screenXs) {
    width: 100%;
    margin: 20px 0 0;
  }
}

.checkbox {
  display: inline-block;
  font-family: $fontFamily;
}

.checkboxWrapper {
  display: inline-block;
  margin: 10px;
}

.counter {
  display: inline-block;
  margin-left: 5px;
  margin-bottom: 6px;
  background-color: $cancelColor;
  color: #fff;
}
