@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.inputTagsWrp {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  &.isNarrow {
    margin-bottom: 0;
  }

  &:not(.isDisabled):not(.isInvalid).isActive > .inputTagsContainer {
    border-color: $secondary3Color;
  }

  &:not(.isDisabled).isActive > .inputTagsContainer {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  }

  &.isPrepended > .inputTagsContainer {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  &.isAppended > .inputTagsContainer {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.isDisabled > .inputTagsContainer {
    background-color: $defaultBackgroundColor;

    &:hover {
      border-color: $secondary2Color;
    }
  }

  &.isInvalid {
    margin-bottom: 0;

    > .inputTagsContainer {
      border-color: $cancelColor;
    }
  }
}

.inputTagsContainer {
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  border: solid 1px $secondary2Color;
  border-radius: 3px;
  padding: 6px 9px 0;
  font-size: 14px;
  letter-spacing: 0.01em;
  max-height: 100px;
  min-height: 36px;
  overflow: auto;

  &:after { // fixes problem with overflow and padding in IE11
    content: '';
    display: block;
    padding-bottom: 2px;
  }
}

.errorMessageWrapper {
  color: $cancelColor;
  font-size: 11px;
  line-height: 20px;
}
