@import "@dealroadshow/uikit/core/styles/variables/_colors.scss";

.manageCategoriesModal {
  :global(.modalOverlay) {
    overflow-y: hidden;
  }
  :global(.modalDialog) {
    max-width: 600px;
  }

  :global(.modalBody) {
    padding: 20px 0;

    .modalForm {
      position: relative;
    }

    .tabs {
      padding-left: 20px;
    }

    .isDisabled {
      cursor: not-allowed;
      pointer-events: none;
    }

    .modalRegions {
      position: absolute;
      right: 5px;
      top: 8px;
    }

    .modalTable {
      max-height: calc(100vh - 300px);
    }

    .addCategoryButton {
      margin-top: 10px;
      i {
        display: inline-block;
        position: relative;
        top: 3px;
        fill: $actionColor;
        margin-right: 10px;
      }
      &:hover {
        i {
          fill: $actionColorHover;
        }
      }
    }

    .indexCol {
      padding-bottom: 14px !important;
      padding-top: 14px !important;
    }

    .draggableCol {
      padding-right: 0 !important;
      padding-left: 0 !important;
      text-align: center;
    }

    .modalButtons {
      margin-top: 20px;
      margin-left: 20px;
    }

    .actionCol {
      text-align: right;
      white-space: nowrap;

      i {
        fill: $actionColor;
      }

      &:hover {
        i {
          fill: $actionColorHover;
        }
      }
    }
  }
}
