@import "@/Framework/GlobalStyles/page.scss";
@import "@dealroadshow/uikit/core/styles/mixins/mq.scss";
@import "@dealroadshow/uikit/core/styles/screen/screen.scss";
@import "@/Framework/GlobalStyles/variables.scss";

.navigation {
  @extend .pageNavigation;
  min-height: 36px;
  height: auto;
}

.links {
  @extend .pageNavigationLinks;

  a {
    margin-right: 40px;

    &.isActiveNavLink {
      color: $titleColor;
    }

    &.isDisabledNavLink {
      color: $secondaryColor;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @include mqMaxWidth($screenS) {
    width: 100%;
    position: relative;
    display: block;
    float: none;
  }
}

.actions {
  position: relative;
}
